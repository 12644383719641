import {Place} from '@material-ui/icons'
import {PlaceTagCreate} from './PlaceTagCreate'
import {PlaceTagEdit} from './PlaceTagEdit'
import {PlaceTagList} from './PlaceTagList'

export default {
  name: 'places_bot_plugin/placetag',
  icon: Place,
  list: PlaceTagList,
  create: PlaceTagCreate,
  edit: PlaceTagEdit,
};
