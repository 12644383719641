import {ChromeReaderMode} from '@material-ui/icons'
import {ListGuesser} from 'react-admin'
import {HtmlWidgetCreate} from './HtmlWidgetCreate'
import {HtmlWidgetEdit} from './HtmlWidgetEdit'
import {HtmlWidgetList} from './HtmlWidgetList'

export default {
  icon: ChromeReaderMode,
  //list: HtmlWidgetList,
  show: ListGuesser,
  create: HtmlWidgetCreate,
  edit: HtmlWidgetEdit,
};
