import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceManyField,
  SingleFieldList,
  useTranslate,
  Filter,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import ImageFieldPreview from '../../../components/ImageFieldPreview/ImageFieldPreview'

const useStyles = makeStyles({
  imgContainer: {
    padding: 16,
    '& img': {
      height: '100%',
      width: '100%',
      maxWidth: 240,
      maxHeight: 120,
      objectFit: 'contain',
      objectPosition: 'left',
      display: 'block',
      margin: 0,
    }
  }
})

const Image = props => {
  const classes = useStyles()
  let ids = props.ids

  if (props.ids && props.ids.length) {
    ids = props.ids.slice(0, 1)
  }

  return (
    <SingleFieldList {...props} ids={ids} linkType={false}>
      <ImageFieldPreview source="image" className={classes.imgContainer}/>
    </SingleFieldList>
  )
}

const PostFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>

      <ReferenceArrayInput
        fullWidth
        alwaysOn
        perPage={10e3}
        label={translate('resources.places_bot_plugin/placetag.name', 2)}
        source="tags"
        reference="places_bot_plugin/placetag"
      >
        <SelectArrayInput fullWidth optionText="name"/>
      </ReferenceArrayInput>
    </Filter>
  )
};

const Images = props => {
  return (
    <ReferenceManyField {...props} perPage={10000} label="Image" reference="places_bot_plugin/placeimage" target="place">
      <Image/>
    </ReferenceManyField>
  )
}

export const placeList = props => {
  return (
    <List filters={<PostFilter/>} perPage={25} {...props} >
      <Datagrid rowClick="edit" st>
        {/*<TextField source="id"/>*/}
        <TextField source="name"/>
        {/*<BooleanField source="is_default"/>*/}

        <BooleanField source="is_transit"/>
        <Images/>
      </Datagrid>
    </List>
  )
}