import {AccountBox} from '@material-ui/icons'
import {UserList} from './UserList'
import {UserEdit} from './UserEdit'
import {UserCreate} from './UserCreate'

export default {
  name: 'infobot/user',
  icon: AccountBox,
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
};
