import React from 'react'
import {
  DateField,
  ReferenceField,
  ReferenceManyField,
  Filter,
  DateInput,
  Datagrid,
  TextField,
  useTranslate,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import {Route, useLocation, useHistory} from 'react-router-dom'
import i18nProvider, {languages} from '../../../../../providers/i18nProvider'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 800
  },
  table: {
    width: '100%',
  },
  headerCell: {
    display: 'none',
  },
  rowCell: {
    padding: '6px !important'
  },
}))

const PostFilter = (props) => {
  const {currentLanguage} = props
  const translate = useTranslate()
  return (
    <Filter {...props}>

      <ReferenceInput
        perPage={10000}
        source="question"
        reference="poll_bot_plugin/pollquestion"
        label={translate('resources.poll_bot_plugin/pollquestion.name', 1)}
        alwaysOn
      >
        <SelectInput
          optionText={`text_${currentLanguage.locale}`}
          allowEmpty={true}
          emptyText={translate('ra.boolean.null')}
        />
      </ReferenceInput>

      <DateInput
        label={translate('resources.poll_bot_plugin/pollanswer.fields.created_at')}
        source="created_at"
        alwaysOn
      />
    </Filter>
  )
}

export const PollAnswerList = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const location = useLocation()
  const history = useHistory()
  const currentLanguage = languages.find(l => l.locale === i18nProvider.getLocale())
  const [filters, setFilters] = React.useState({})

  return (
    <Route path="/poll_bot_plugin/poll/:id/answers">
      {({match, location: {search}}) => {
        const urlParams = new URLSearchParams(search)
        if (!match) {
          if (urlParams.has('answers')) {
            urlParams.delete('answers')
            history.push(`${location.pathname}?${urlParams.toString()}`)
          }
          return null
        }

        return (
          <Box className={classes.plane}>
            <PostFilter setFilters={setFilters} currentLanguage={currentLanguage}/>

            <ReferenceManyField
              {...props}
              perPage={10000}
              label="Image"
              reference="poll_bot_plugin/pollanswer"
              basePath={'test'}
              filter={filters}
              target="poll"
            >
              <Datagrid
                {...props}
                fullWidth
                classes={{
                  table: classes.table,
                  // headerCell: classes.headerCell,
                  // rowCell: classes.rowCell,
                }}
              >
                <TextField source="bot_user"/>
                <DateField showTime={true} source="created_at"/>
                <ReferenceField link={false} source="question" reference="poll_bot_plugin/pollquestion">
                  <TextField source={`text_${currentLanguage.locale}`}/>
                </ReferenceField>
                <TextField source="answer"/>
              </Datagrid>
            </ReferenceManyField>
          </Box>
        )
      }}
    </Route>
  )
}