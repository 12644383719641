import React from 'react'
import {Avatar} from '@material-ui/core'
import {AccountCircle, HelpOutline, CheckCircleOutline, HourglassEmpty} from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles'
import {ChatMessageAttachments} from './ChatMessageAttachments'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    margin: `${theme.spacing(1)}px 0`,
    display: 'flex',
  },
  rootOperator: {
    justifyContent: 'flex-end',
  },
  avatar: {
    position: 'absolute',
    width: 32,
    height: 32,
    backgroundColor: '#2196f3',
    color: '#fff',
  },
  operatorAvatar: {
    right: 0,
  },
  icon: {
    width: 30,
    height: 30,
  },
  time: {
    display: 'flex',
    position: 'absolute',
    padding: `1px 42px`,
    fontSize: 11,
    color: 'rgba(128, 128, 128, 1)',
  },
  operatorTime: {
    right: 0,
  },
  message: {
    padding: `16px 40px 0 40px`,
  },
  text: {
    display: 'inline-block',
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: `4px 18px 18px 18px`,
    padding: `14px`,
    fontSize: 14,
  },
  operatorText: {
    borderRadius: `18px 4px 18px 18px`,
  },
  operatorName: {
    color: '#2196f3',
    marginBottom: 8,
    textAlign: 'right',
  },
  isSent: {
    marginLeft: 4,
    fontSize: 12,
  },
  sending: {
    animation: `spin 2s linear infinite`,
  }
}))

export const ChatMessage = props => {
  const {record, attachments, stickyEffect} = props
  const classes = useStyles()
  const isUser = record.author_type === 'user'

  return (
    <div className={`${classes.root} ${isUser ? null : classes.rootOperator}`}>
      <Avatar className={`${classes.avatar} ${isUser ? null : classes.operatorAvatar}`}>
        {isUser ? <AccountCircle className={classes.icon}/> : <HelpOutline className={classes.icon}/>}
      </Avatar>

      <div className={`${classes.time} ${isUser ? null : classes.operatorTime}`}>
        {new Date(record.created).toLocaleString()}

        {!isUser && (record.is_sent ?
            <CheckCircleOutline className={classes.isSent}/>
            :
            <HourglassEmpty className={`${classes.isSent} ${classes.sending}`}/>
        )}
      </div>

      <div className={`${classes.message} ${isUser ? null : classes.operatorMessage}`}>
        <div className={`${classes.text} ${isUser ? null : classes.operatorText}`}>
          {!isUser && <div className={classes.operatorName}>{record.operator_screen_name}</div>}
          {record.message}
          {record.has_attachments && <ChatMessageAttachments attachments={attachments} stickyEffect={stickyEffect} record={record}/>}
        </div>
      </div>
    </div>
  )
}