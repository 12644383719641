import React from 'react'
import {Edit, TextInput, NumberInput, SimpleForm, ReferenceInput, SelectInput, useTranslate} from 'react-admin'
import {parse} from 'query-string'

export const ButtonWidgetEdit = props => {
  let {screen} = parse(props.location.search)
  screen = screen ? parseInt(screen, 10) : null
  const redirect = screen ? `/infobot/screen/${screen}` : false
  const translate = useTranslate()

  return (
    <>
      <Edit undoable={false} {...props}>
        <SimpleForm
          initialValues={{text: '_', screen}}
          redirect={redirect}
        >
          {!screen && <TextInput source="screen"/>}
          <TextInput source="iname"  label={translate('resources.faq_bot_plugin/buttonwidget.fields.iname')}/>
          <NumberInput source="order"  label={translate('resources.faq_bot_plugin/buttonwidget.fields.order')}/>
          <TextInput source="text_ru"  label={translate('resources.faq_bot_plugin/buttonwidget.fields.text_ru')}/>
          <TextInput source="text_en"  label={translate('resources.faq_bot_plugin/buttonwidget.fields.text_en')} />
          <TextInput source="action_args" label={translate('resources.faq_bot_plugin/buttonwidget.fields.action_args')}/>
          {/*<TextInput source="screen"/>*/}
          {/*<TextInput source="go_to_screen"/>*/}
          {/*<TextInput source="action"/>*/}

          <ReferenceInput perPage={10000} source="action" reference="infobot/action">
            <SelectInput optionText="iname" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </>
  )
}
