import React, {useEffect, useState} from 'react'
import {useEditController, useDataProvider} from 'react-admin'
import {ChatMessageList} from '../chatMessage/ChatMessageList'

const ChatUserEdit = ({onCancel, ...props}) => {
  const controllerProps = useEditController(props)
  const dataProvider = useDataProvider()
  const [currentOperator, setCurrentOperator] = useState(undefined)

  useEffect(() => {
    dataProvider('CUSTOM_GET', '/api/chat/operator/current/', {}).then(data => setCurrentOperator(data.json))
  }, [])

  if (!controllerProps.record) {
    return null
  }
  const title = controllerProps.record.telegram_user_name ?? controllerProps.record.telegram_user_id

  return (
    <ChatMessageList
      chatUser={controllerProps.record}
      filter={{chat_user: props.id}}
      title={title}
      onClose={onCancel}
      currentOperator={currentOperator}
    />
  )
}

export default ChatUserEdit
