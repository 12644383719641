import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import {AttachFile} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export default function Upload(props) {
  const {accept} = props
  const classes = useStyles()

  const onInputChange = async (e) => {
    const files = [...e.target.files]

    const promises = files.map((file) => {
      return new Promise(res => {
        const reader = new FileReader()
        reader.onload = (e) => res({base64: e.target.result, file: file})
        reader.readAsDataURL(file)
      })
    })

    props.onFileLoad(await Promise.all(promises))
  }

  return (
    <div className={`${classes.container}`}>
      <input
        multiple
        accept={accept ?? "image/*"}
        id="raised-button-file"
        type="file"
        style={{display: 'none'}}
        onChange={onInputChange}
      />
      <IconButton
        component={'label'}
        htmlFor="raised-button-file"
        color="primary"
        className={classes.attachmentButton}
      >
        <AttachFile/>
      </IconButton>
    </div>
  )
}
