import React from 'react'
import TextField from '@material-ui/core/TextField'
import {useInput} from 'ra-core'
import MonacoEditor from 'react-monaco-editor'

export const Editor = props => {
  const value = typeof props.value === 'string' ? props.value : JSON.stringify(props.value)
  return (
    <MonacoEditor
      width="100%"
      height="142"
      language="json"
      value={value}
      options={{
        lineNumbers: 'off',
        glyphMargin: false,
        folding: false,
        lineDecorationsWidth: 0,
        lineNumbersMinChars: 0,
        automaticLayout: true,
        minimap: {enabled: false},
      }}
      onChange={props.onChange}
      // editorDidMount={::this.editorDidMount}
    />
  )
}

export const JsonEditor = ({label, ...props}) => {
  const input = useInput({'name': 'action_args'})
  const {
    input: {name, value, onChange},
    meta: {touched, error},
    isRequired
  } = input

  return (
    <TextField
      fullWidth
      multiline
      InputLabelProps={{shrink: true}}
      variant="outlined"
      label={label}
      InputProps={{inputComponent: Editor}}
      inputRef={e => console.log(e)}
      inputProps={{...props, source: name, value, onChange}}
    />
  )
}
