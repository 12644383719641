import {useDataProvider} from 'ra-core'
import {useEffect, useState} from 'react'

function changeFavicon(src) {
  const link = document.createElement('link')
  link.id = 'dynamic-favicon'
  link.rel = 'shortcut icon'
  link.href = src
  const oldLink = document.getElementById('dynamic-favicon')
  if (oldLink) {
    document.head.removeChild(oldLink)
  }
  document.head.appendChild(link)
}

let cacheConfig = JSON.parse(localStorage.getItem('config')) ?? {}
let request = null

export const useConfig = () => {
  const dataProvider = useDataProvider()
  const [config, setConfig] = useState({...cacheConfig})

  useEffect(() => {
    if (!request) {
      request = dataProvider.getOne('infobot/config', {id: 1})
    }
    request.then(res => {
      setConfig(res.data)
      localStorage.setItem('config', JSON.stringify(res.data))
      document.title = res.data.title ?? ' '
      changeFavicon(res.data.favicon)
      cacheConfig = {...res.data}
    })
    changeFavicon(config.favicon)
    document.title = config.title ?? ' '
    //favicon
  }, [])

  return config
}
