import React from 'react'
import {useCreateController, useTranslate, TextInput, SimpleForm, useRefresh, useRedirect, ImageInput, ImageField} from 'react-admin'
import {Toolbar} from './Toolbar'

export const PollQuestionCreate = props => {
  const resource = 'poll_bot_plugin/pollquestion'
  const refresh = useRefresh()
  const translate = useTranslate()
  const redirect = useRedirect()

  const {location, match} = props
  const controllerProps = useCreateController({
    match,
    location,
    resource,
    basePath: ''
  })

  const save = controllerProps.save
  controllerProps.save = (data, redirectTo, _a = {}) => {
    _a.onSuccess = () => {
      refresh()
      redirect(`/poll_bot_plugin/poll/${props.poll}/questions`)
    }
    save(data, redirectTo, _a)
  }

  return (
    <SimpleForm
      undoable={false}
      initialValues={{poll: Number(props.poll), order: -1}}
      basePath={controllerProps.basePath}
      record={controllerProps.record}
      save={controllerProps.save}
      version={controllerProps.version}
      resource={resource}
      toolbar={<Toolbar/>}
    >
      <TextInput fullWidth multiline rows="8" variant="outlined" source="text_ru"/>
      <TextInput fullWidth multiline rows="8" variant="outlined" source="text_en"/>
      <ImageInput source="image|rawFile" label="" accept="image/*">
        <ImageField source="image" title="title"/>
      </ImageInput>
    </SimpleForm>
  )
}
