export const numberToColor = (number, alpha) => {

  let tint = number + 0x6D2B79F5
  tint = Math.imul(tint ^ tint >>> 15, tint | 1)
  tint ^= tint + Math.imul(tint ^ tint >>> 7, tint | 61)
  tint = ((tint ^ tint >>> 14) >>> 0)

  let r = (tint & 0xFF0000) >> 16
  let g = (tint & 0x00FF00) >> 8
  let b = (tint & 0x0000FF) >> 0

  return `rgba(${r},${g},${b},${alpha})`
}