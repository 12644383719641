import * as React from "react";
import { Edit, SimpleForm, TextInput, DateInput, ReferenceManyField, Datagrid, TextField, DateField, EditButton } from 'react-admin';

export const PositionEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name_ru"/>
            <TextInput source="name_en"/>
        </SimpleForm>
    </Edit>
);
