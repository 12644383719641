import React from 'react'
import {SimpleForm, Edit, SelectInput} from 'react-admin'

export const locales = [
  {id: 'ru', name: 'language.russian'},
  {id: 'en', name: 'language.english'},
]

const Profile = ({staticContext, ...props}) => {
  return (
    <Edit
      id="profile"
      resource="profile"
      // basePath="/profile"
      redirect={false}
      {...props}
    >
      <SimpleForm>
        <SelectInput
          name="locale"
          label="language._"
          choices={locales}
        />
      </SimpleForm>
    </Edit>
  )
}

export default Profile

