import React from 'react'
import {
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  TextField,
  DeleteButton,
  useTranslate,
} from 'react-admin'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import {Drawer, Button, ListItemText} from '@material-ui/core'
import {Route, useLocation, useHistory} from 'react-router-dom'
import {Edit, Close, Add} from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import {PlacePathCreate} from './PlacePathCreate'

const useStyles = makeStyles(theme => ({
  drawer: {
    maxWidth: '100vw',
    width: 500,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  plane: {
    width: '100%',
    maxWidth: 800
  },
  imgContainer: {
    padding: 16,
    '& img': {
      height: '100%',
      width: '100%',
      maxWidth: 320,
      objectFit: 'contain',
      objectPosition: 'left',
      display: 'block',
      margin: 0,
    }
  },
  headerCell: {
    display: 'none',
  },
  rowCell: {
    padding: '6px !important',
    '&:first-child': {
      width: '100%',
    }
  },
  test: {
    paddingLeft: 4,
  }
}))

const PlaceName = props => {
  const classes = useStyles()

  return (
    <ListItemText
      className={classes.test}
      primary={props.record.name}
      secondary={props.record.description}
    />
  )
}

const PathGrid = props => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const translate = useTranslate()

  return (
    <>
      <Box m={1}>
        <Button
          startIcon={<Add/>}
          color="primary"
          onClick={() => history.push(`/places_bot_plugin/place/${props.id}/path?path=create`)}
        >
          {translate('resources.places_bot_plugin/place.add_path', 1)}
        </Button>
      </Box>
      <Datagrid
        {...props}
        fullWidth
        classes={{
          headerCell: classes.headerCell,
          rowCell: classes.rowCell,
        }}
      >
        <ReferenceField link={false} source="target_place" reference="places_bot_plugin/place">
          <PlaceName/>
        </ReferenceField>

        <DeleteButton undoable={false} redirect={`/places_bot_plugin/place/${props.id}/path`}/>
      </Datagrid>
    </>
  )
}

export const PlacePathList = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const location = useLocation()
  const history = useHistory()

  const drawerClose = () => history.push(location.pathname)

  return (
    <Route path="/places_bot_plugin/place/:id/path">
      {({match, location: {search}}) => {
        const urlParams = new URLSearchParams(search)
        if (!match) {
          if (urlParams.has('path')) {
            urlParams.delete('path')
            history.push(`${location.pathname}?${urlParams.toString()}`)
          }
          return null
        }
        let path = urlParams.get('path')
        if (path !== 'create') {
          path = null
        }

        return (
          <>
            <Box className={classes.plane}>
              <ReferenceManyField
                {...props}
                perPage={10000}
                className={classes.plane}
                label="Pew"
                filter={{source_place: props.id}}
                reference="places_bot_plugin/placepath"
              >
                <PathGrid id={props.id}/>
              </ReferenceManyField>
            </Box>

            <Drawer
              open={path}
              anchor="right"
              onClose={drawerClose}
              classes={{paper: classes.drawerPaper}}
            >
              <div className={classes.drawer}>
                <div className={classes.title}>
                  <Typography variant="h6">
                    {translate('resources.places_bot_plugin/place.add_path')}
                  </Typography>
                  <IconButton onClick={drawerClose}>
                    <Close/>
                  </IconButton>
                </div>

                <PlacePathCreate {...props} place={props.id}/>
              </div>
            </Drawer>
          </>
        )
      }}
    </Route>
  )
}
