import React from 'react'
import {ReferenceManyField, DeleteButton, useTranslate} from 'react-admin'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import {Drawer, Button} from '@material-ui/core'
import {Route, useLocation, useHistory, Link} from 'react-router-dom'
import {Close, Add} from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import {DraggableList} from '../../../../components/DraggableList/DraggableList'
import {RoutePlaceCreate} from './RoutePlaceCreate'

const useStyles = makeStyles(theme => ({
  drawer: {
    maxWidth: '100vw',
    width: 500,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  plane: {
    width: '100%',
    maxWidth: 800,
    margin: 0,
  },
  imgContainer: {
    padding: 16,
    '& img': {
      height: '100%',
      width: '100%',
      maxWidth: 320,
      objectFit: 'contain',
      objectPosition: 'left',
      display: 'block',
      margin: 0,
    }
  },
  headerCell: {
    display: 'none',
  },
  rowCell: {
    padding: '6px !important'
  },
}))

export const RoutePlaceEdit = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const location = useLocation()
  const history = useHistory()

  const drawerClose = () => history.push(location.pathname)

  return (
    <Route path="/places_bot_plugin/route/:id/place">
      {({match, location: {search}}) => {
        const urlParams = new URLSearchParams(search)
        if (!match) {
          if (urlParams.has('place')) {
            urlParams.delete('place')
            history.push(`${location.pathname}?${urlParams.toString()}`)
          }
          return null
        }
        const place = urlParams.get('place')

        return (
          <>
            <Box className={classes.plane} m={1}>
              <Button
                startIcon={<Add/>}
                color="primary"
                onClick={() => history.push(`/places_bot_plugin/route/${props.id}/place?place=create`)}
              >
                {translate('resources.places_bot_plugin/route.add_place', 1)}
              </Button>

              <ReferenceManyField perPage={10000} fullWidth {...props} label="Pew" reference="places_bot_plugin/routeplace" target="route">
                <DraggableList
                  id={props.id}
                  items={props.places}
                  setItems={props.setPlaces}
                  primary={item =>
                    <Link
                      to={`/places_bot_plugin/place/${item.place}`}
                      target='_blank'
                    >
                      {item.name}
                    </Link>
                  }
                  secondary={item => item.description}
                  controls={item =>
                    <DeleteButton
                      style={{flexShrink: 0, margin: `0 16px`}}
                      record={item}
                      resource='places_bot_plugin/routeplace'
                      undoable={false}
                      redirect={`/places_bot_plugin/route/${props.id}/place`}
                    />
                  }
                />
              </ReferenceManyField>
            </Box>

            <Drawer
              open={place}
              anchor="right"
              onClose={drawerClose}
              classes={{paper: classes.drawerPaper}}
            >
              <div className={classes.drawer}>
                <div className={classes.title}>
                  <Typography variant="h6">
                    {translate('resources.places_bot_plugin/route.add_place', 1)}
                  </Typography>
                  <IconButton onClick={drawerClose}>
                    <Close/>
                  </IconButton>
                </div>

                <RoutePlaceCreate route={props.id}/>
              </div>
            </Drawer>
          </>
        )
      }}
    </Route>
  )
}
