import * as React from "react";
import { Create, SimpleForm, TextInput, DateInput, ReferenceManyField, Datagrid, TextField, DateField, EditButton } from 'react-admin';

export const PositionCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name_ru"/>
            <TextInput source="name_en"/>
        </SimpleForm>
    </Create>
);
