import React from 'react'
import {List, Datagrid, TextField, BooleanField, useTranslate} from 'react-admin'

export const RouteList = props => {
  const translate = useTranslate()

  return (
    <List perPage={25} {...props} >
      <Datagrid rowClick="edit">
        {/*<TextField source="id"/>*/}
        <TextField source="name" label={translate('resources.places_bot_plugin/route.name', 1)}/>
        <BooleanField source="can_choose_start_place" label={translate('resources.places_bot_plugin/route.fields.can_choose_start_place', 1)}/>
        <BooleanField source="is_one_way_mode"  label={translate('resources.places_bot_plugin/route.fields.is_one_way_mode', 1)}/>
      </Datagrid>
    </List>
  )
}
