import React, {useEffect, useState} from 'react'
import {useQuery, useTranslate} from 'react-admin'
import {makeStyles} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import {Line} from 'react-chartjs-2'

const useStyles = makeStyles(theme => ({
  chart: {
    marginTop: 16,
    position: 'relative',
    marginBottom: 24,
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 64px)',
      left: 24,
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 24px)',
      left: 8,
    },
  },
  loader: {
    margin: 36,
  }
}))

const channelColors = {
  'all': `0,169,143`,
  'telegram': `0,136,204`,
}
const channelColor = (channel, alpha) => {
  const color = channelColors[channel] ? channelColors[channel] : '0,136,204'
  return `rgba(${color},${alpha})`
}

const RenderChart = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const [data, setData] = useState(null)
  const {info} = props

  useEffect(() => {
    const channels = {
      //'all': {}
    }
    const days = {}
    info.forEach(info => {
      days[info.day] = {}
      channels[info.channel] = true
      //days[info.day]['all'] = 0
    })
    info.forEach(info => {
      days[info.day][info.channel] = info.uniq_user_count
      //days[info.day]['all'] += info.uniq_user_count
    })

    const labels = Object.keys(days).sort((a, b) => new Date(a) - new Date(b))
    const datasets = Object.keys(channels).map(channel => {
      return {
        label: translate(`charts.channel.${channel}`),
        data: labels.map(data => days[data][channel] ? days[data][channel] : 0),
        fill: false,
        backgroundColor: channelColor(channel, .4),
        borderColor: channelColor(channel, 1),
      }
    })

    setData({
      labels,
      datasets,
    })
  }, [info])

  if (!data) {
    return <CircularProgress className={classes.loader}/>
  }

  return (
    <div className={classes.chart}>
      <Line data={data}/>
    </div>
  )
}

export const DailyStats = props => {
  const classes = useStyles()

  const {data, loading, error} = useQuery({
    type: 'getList',
    resource: 'infobot/dailystat',
    payload: {
      filter: {},
      pagination: {page: 1, perPage: 10000},
      sort: {field: 'id'}
    },
  })

  return (
    <div>
      {loading ? (
        <CircularProgress className={classes.loader}/>
      ) : (
        <RenderChart info={data}/>
      )}
    </div>
  )
}