import React from 'react'
import {List, Datagrid, TextField, BooleanField, useTranslate} from 'react-admin'
import Button from '@material-ui/core/Button'

const Start = props => {
  const translate = useTranslate()
  return <Button
    color="primary"
    onClick={e => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    }}
  >
    {translate('resources.infobot/bot.btnStart')}
  </Button>
}
const Stop = props => {
  const translate = useTranslate()
  return <Button
    color="primary"
    onClick={e => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    }}
  >
    {translate('resources.infobot/bot.btnStop')}
  </Button>
}
const RestartButton = props => {
  const translate = useTranslate()
  if(props.record.status !== 'running') {
    return null
  }
  return <Button
    color="primary"
    onClick={e => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    }}
  >
    {translate('resources.infobot/bot.btnRestart')}
  </Button>
}

const BotButton = props => {
  if(props.record.status === 'stopped') {
    return <Start/>
  } else {
    return <Stop/>
  }
}

export const BotList = props => {
  const translate = useTranslate()

  return (
    <List perPage={25} {...props} >
      <Datagrid rowClick="edit">
        <TextField source="iname"/>
        <TextField source="status"/>

        <RestartButton />
        <BotButton />
      </Datagrid>
    </List>
  )
}
