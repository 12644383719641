import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Modal from '@material-ui/core/Modal'
import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWindow: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: 'relative',
    '&:focus': {
      outline: 'none',
    }
  },
  modalImg: {
    maxWidth: 'calc(100vw - 32px)',
    maxHeight: 'calc(100vh - 32px)',
    display: 'block',
  },
  modalClose: {
    position: 'absolute',
    background: 'rgba(255,255,255,.1)',
    top: 8,
    right: 8,
  }
}))

export const ImagePreview = props => {
  const {src, show, onClose} = props
  const [image, setImage] = useState(null)
  const classes = useStyles()

  const setupImage = (src) => {
    if(!src) {
      setImage({
        src: undefined,
        width: undefined,
        height: undefined,
      })
      return
    }
    const img = new Image()
    img.addEventListener('load', function (e) {
      setImage({
        src,
        width: this.naturalWidth,
        height: this.naturalHeight,
      })
    })
    img.src = src
  }

  useEffect(() => setupImage(src), [src])

  return (
    <Modal
      open={!!show}
      className={classes.modal}
      onClose={onClose}
    >
      <div className={classes.modalWindow}>
        {image && <img src={image.src} className={classes.modalImg}/>}
        <IconButton
          size="small"
          className={classes.modalClose}
          onClick={onClose}
        >
          <CloseIcon style={{fontSize: 24}}/>
        </IconButton>
      </div>
    </Modal>
  )
}