import React, {useEffect, useState} from 'react'
import {Field, withTypes} from 'react-final-form'
import {useLocation} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
import {Notification} from 'react-admin'
import {useTranslate, useLogin, useNotify} from 'ra-core'
import {Logo} from '../components/Logo/Logo'
import background from './background.jpg'
import {useConfig} from './useConfig'

const useStyles = makeStyles(theme => ({
  main: p => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: `url(${p.background}) center center`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }),
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: p => ({
    background: p.logo ? `url(${p.logo.src}) center center` : `rgba(0,0,0,0)`,
    height: p.logo?.height ?? 0,
    width: p.logo?.width ?? 0,
    margin: `0 auto`,
  }),
  icon: {
    // backgroundColor: theme.palette.primary.main,
    width: 60,
    height: 60,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
}))

const renderInput = ({
                       meta: {touched, error} = {touched: false, error: undefined},
                       input: {...inputProps},
                       ...props
                     }) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const {Form} = withTypes()

const Login = () => {
  const {login_background_image, login_logo} = useConfig()
  const [logo, setLogo] = useState(null)
  const [loading, setLoading] = useState(false)
  const translate = useTranslate()
  const classes = useStyles({background: login_background_image, logo})
  const notify = useNotify()
  const login = useLogin()
  const location = useLocation()

  useEffect(() => {
    const img = new Image()
    img.addEventListener('load', function (e) {
      setLogo({
        src: login_logo,
        width: this.naturalWidth,
        height: this.naturalHeight,
      })
    })
    img.src = login_logo
  }, [login_logo])

  const handleSubmit = (auth) => {
    setLoading(true)
    login(auth, location.state ? location.state.nextPathname : '/').catch(
      (error) => {
        setLoading(false)
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning'
        )
      }
    )
  }

  const validate = (values) => {
    const errors = {}
    if (!values.username) {
      errors.username = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                {/*<Avatar className={classes.icon}>*/}
                {/*  <Logo/>*/}
                {/*</Avatar>*/}
              </div>
              {/*<div className={classes.hint}>*/}
              {/*  Hint: demo / demo*/}
              {/*</div>*/}
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="username"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('ra.auth.username')}
                    disabled={loading}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="password"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                    />
                  )}
                  {translate('ra.auth.sign_in')}
                </Button>
              </CardActions>
            </Card>
            <Notification/>
          </div>
        </form>
      )}
    />
  )
}

export default Login
