import {BugReport} from '@material-ui/icons'
import {ListGuesser, EditGuesser, ShowGuesser} from 'react-admin'

export default {
  icon: BugReport,
  show: ShowGuesser,
  edit: EditGuesser,
  // list: process?.env?.NODE_ENV === "development" ? ListGuesser : undefined,
  // create: chatUserCreate,
};
