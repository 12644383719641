import * as React from "react";
import PropTypes from 'prop-types';
import IconStar from '@material-ui/icons/Stars';
import IconLike from '@material-ui/icons/ThumbUp';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        opacity: 0.87,
        whiteSpace: 'nowrap',
    },
    large: {
        width: 20,
        height: 20,
    },
    small: {
        width: 15,
        height: 15,
    },
});

const RateField = ({ source, is_like_source, record = {}, size = 'large' }) => {
    const classes = useStyles();
    if (record[is_like_source]) {
        return <IconLike key="1" />
    }

    return record ? (
        <span>
            {Array(record[source])
                .fill(true)
                .map((_, i) => (
                    <IconStar
                        key={i}
                        className={
                            size === 'large' ? classes.large : classes.small
                        }
                    />
                ))}
        </span>
    ) : null;
};


RateField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default RateField;
