import React from 'react'
import {Route} from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import {useRedirect} from 'react-admin'
import {WidgetCreate} from './WidgetCreate'

export const WidgetCreateRoute = (props, widget) => {
  const screenURL = `/infobot/screen/${props.id}`
  const redirect = useRedirect()

  const drawerClose = () => redirect(screenURL)

  return (
    <React.Fragment key={widget.type}>
      <Route key={widget.type} path={`/infobot/screen/:screenID/${widget.type}/:id`}>
        {({match}) => {
          const isMatch = !!(match && match.params && match.params.id === 'create')

          return (
            <>
              <Drawer
                open={isMatch}
                anchor="right"
                onClose={drawerClose}
              >
                {isMatch ? (
                  <WidgetCreate
                    widget={widget}
                    onCancel={drawerClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </>
          )
        }}
      </Route>
    </React.Fragment>
  )
}