import React, {useState} from 'react'
import {
  Edit,
  FormTab,
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TabbedForm,
  SelectArrayInput,
  ReferenceArrayInput,
  BooleanInput,
  useDataProvider,
  useQuery,
  useTranslate,
} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {ChangePassword} from './ChangePassword'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 400
  },
}))

const InjectFields = props => {
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const [isOperator, setIsOperator] = useState(!!props.record.chat_operator_screen_name)
  const [chatOperator, setChatOperator] = useState(undefined)
  props.record.is_operator = isOperator

  const {data, loading, error} = useQuery({
    type: 'getList',
    resource: 'chat_bot_plugin/chatoperator',
    payload: {
      filter: {user: props.record.id},
      pagination: {page: 1, perPage: 10000},
      sort: {field: 'id'},
    },
  })
  if (!loading && chatOperator === undefined) {
    if (data.length) {
      setChatOperator(data[0])
    } else {
      setChatOperator(null)
    }
  }

  const save = props.save
  const injectSave = async (data, redirectTo, _a = {}) => {
    data.username = data.email

    if (data.is_operator) {
      if (chatOperator) {
        await dataProvider.update('chat_bot_plugin/chatoperator', {
          id: chatOperator.id,
          data: {
            ...chatOperator,
            screen_name: data.chat_operator_screen_name ? data.chat_operator_screen_name : '',
          },
        })
      } else {
        await dataProvider.create('chat_bot_plugin/chatoperator', {
          data: {
            screen_name: data.chat_operator_screen_name ? data.chat_operator_screen_name : '',
            user: props.record.id,
          }
        })
      }
    } else {
      if (chatOperator) {
        await dataProvider.delete('chat_bot_plugin/chatoperator', {id: chatOperator.id})
      }
    }
    save(data, redirectTo, _a)
  }
  const translate = useTranslate()

  return (
    <TabbedForm {...props} save={injectSave}>
      <FormTab label={translate('resources.infobot/user.tabs.main')}>
        <Box className={classes.plane} m={1}>
          <Box fullWidth>
            <TextInput fullWidth label={translate('resources.infobot/user.fields.email')} source="email"/>
          </Box>

          <Box fullWidth>
            <TextInput fullWidth label={translate('resources.infobot/user.fields.first_name')} source="first_name"/>
          </Box>

          <Box fullWidth>
            <TextInput fullWidth label={translate('resources.infobot/user.fields.last_name')} source="last_name"/>
          </Box>

          <Box fullWidth>
            <TextInput source="patronymic" fullWidth label={translate('resources.infobot/user.fields.patronymic')} />

            <ReferenceInput fullWidth label={translate('resources.infobot/user.fields.position')} source="position" reference="infobot/position">
                <SelectInput optionText="name" />
            </ReferenceInput>
          </Box>

          <ReferenceArrayInput perPage={10e3} label={translate('resources.infobot/user.fields.groups')} source="groups" reference="auth/group">
            <SelectArrayInput fullWidth optionText="name"/>
          </ReferenceArrayInput>

          <BooleanInput onChange={setIsOperator} label={translate('resources.infobot/user.fields.is_operator')} source={'is_operator'}/>
          {isOperator && <TextInput fullWidth label={translate('resources.infobot/user.fields.chat_operator_screen_name')} source="chat_operator_screen_name"/>}
        </Box>
      </FormTab>

      <FormTab label={translate('resources.infobot/user.tabs.change_password')}>
        <Box className={classes.plane} m={1}>
          <ChangePassword user={props.record.id} />
        </Box>
      </FormTab>
    </TabbedForm>
  )
}

export const UserEdit = props => {
  return (
    <Edit undoable={false} {...props} >
      <InjectFields/>
    </Edit>
  )}
