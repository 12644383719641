import React from 'react'
import {List, Datagrid, TextField, ReferenceField, BooleanField} from 'react-admin'

const InjectFields = props => {
  props.ids.forEach(id => props.data[id].is_operator = !!props.data[id].chat_operator_screen_name)

  return (
    <Datagrid {...props} rowClick="edit">
      <TextField source="email"/>
      <TextField source="first_name"/>
      <TextField source="last_name"/>
      <TextField source="patronymic"/>
      <ReferenceField source="position" reference="infobot/position">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="is_operator"/>
      <TextField source="chat_operator_screen_name"/>
    </Datagrid>
  )
}

export const UserList = props => (
  <List perPage={25} {...props}>
    <InjectFields/>
  </List>
)
