import React from 'react';
import { List, Datagrid, TextField  } from 'react-admin';
import { Edit, TabbedForm, FormTab, TextInput  } from 'react-admin';

export const actionList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="iname" />
            <TextField source="source" />
        </Datagrid>
    </List>
);

export const actionEdit = props => (
    <Edit undoable={false} {...props}>
      <TabbedForm>
      <FormTab label="summary">
            <TextInput source="id" />
            <TextInput source="iname" />
            <TextInput source="source" />
      </FormTab>
        <FormTab label="summary">
          <TextInput source="id" />
          <TextInput source="iname" />
          <TextInput source="source" />
        </FormTab>
      </TabbedForm>
    </Edit>
);
