import React from 'react'
import {List, Datagrid, TextField, BooleanField} from 'react-admin'

const InjectFields = props => {
  props.ids.forEach(id => props.data[id].is_operator = !!props.data[id].chat_operator_screen_name)
  return (
    <Datagrid {...props} rowClick="edit">
      <TextField source="name_ru"/>
      <TextField source="name_en"/>
    </Datagrid>
  )
}

export const PositionList = props => (
  <List perPage={25} {...props}>
    <InjectFields/>
  </List>
)
