import React from 'react'
import {List, Datagrid, TextField} from 'react-admin'
import i18nProvider from '../../../providers/i18nProvider'

export const PollList = props => console.log(props) || (
  <List perPage={25} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="iname"/>
      <TextField source={`description_${i18nProvider.getLocale()}`}/>
    </Datagrid>
  </List>
)