import {PhotoSizeSelectActual} from '@material-ui/icons'
import {ImageWidgetCreate} from './ImageWidgetCreate'
// import {ImageWidgetEdit} from './ImageWidgetEdit'
import {ImageWidgetList} from './ImageWidgetList'

export default {
  icon: PhotoSizeSelectActual,
  //list: ListGuesser,
  show: ImageWidgetList,
  create: ImageWidgetCreate,
  //edit: ImageWidgetEdit,
};
