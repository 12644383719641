import React, {useCallback, useEffect} from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  useTranslate,
  BooleanField,
  ReferenceInput,
  SelectInput,
  useRefresh,
} from 'react-admin'
import {Route, useHistory} from 'react-router-dom'
import {Drawer, useMediaQuery, makeStyles} from '@material-ui/core'
import ChatUserEdit from './ChatUserEdit'
import UndefinedBooleanInput from '../../../components/UndefinedBooleanInput/UndefinedBooleanInput'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 600,
  },
  drawerPaper: {
    zIndex: 100,
  },
}))

const UserName = ({record = {}}) => {
  const {telegram_user_name, telegram_user_id} = record
  return (
    <span>{telegram_user_name ?? telegram_user_id}</span>
  )
}

const PostFilter = (props) => {
  const translate = useTranslate()

  return (
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        emptyText={translate('resources.chat_bot_plugin/chatoperator.all', 1)}
        label={translate('resources.chat_bot_plugin/chatoperator.name', 1)}
        perPage={10000}
        source="current_operator"
        reference="chat_bot_plugin/chatoperator"
      >
        <SelectInput optionText="screen_name"/>
      </ReferenceInput>

      <UndefinedBooleanInput alwaysOn source="is_operator_calling"/>
      <UndefinedBooleanInput alwaysOn source="is_dialog_active"/>
    </Filter>
  )
}

const ChatUserList = props => {
  const classes = useStyles()
  const history = useHistory()
  const refresh = useRefresh()
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('md'))

  const handleClose = useCallback(() => {
    history.push('/chat_bot_plugin/chatuser')
  }, [history])

  useEffect(() => {
    const interval = setInterval(refresh, 5e3)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className={classes.root}>
      <Route path="/chat_bot_plugin/chatuser/:id">
        {({match}) => {
          const isMatch = !!(match && match.params)

          return (
            <>
              <List
                filters={<PostFilter/>}
                {...props}
                className={`${classes.list} ${(isMatch && !isXSmall) ? classes.listWithDrawer : null}`}
                perPage={25}
                //sort={{field: 'date', order: 'DESC'}}
              >
                <Datagrid
                  rowClick="edit"
                  classes={{
                    headerRow: classes.headerRow,
                    headerCell: classes.headerCell,
                    rowCell: classes.rowCell,
                  }}
                  optimized
                >
                  <BooleanField source="is_operator_calling"/>
                  <BooleanField source="is_dialog_active"/>
                  <UserName source="telegram_user_id"/>
                  <TextField source="current_operator_screen_name"/>
                </Datagrid>
              </List>

              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {isMatch ? (
                  <ChatUserEdit
                    key={match.params.id}
                    id={match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </>
          )
        }}
      </Route>
    </div>
  )
}

export default ChatUserList
