import React, {Component} from 'react'
import {ListItemText} from '@material-ui/core'
import RootRef from '@material-ui/core/RootRef'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles(theme => ({
  index: {
    height: 56,
    width: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
  },
  indexIcon: {
    height: 28,
    width: 28,
    fontSize: 14,
  },
}))

const Index = props => {
  const classes = useStyles()
  return (
    <div className={classes.index}>
      <Avatar className={classes.indexIcon}>{props.index}</Avatar>
    </div>
  )
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  result.forEach((item, index) => {
    item.order = index
  })
  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  display: 'flex',
  alignItems: 'center',
  ...(isDragging && {
    background: 'rgb(235,235,235)',
  })
})

class DraggableList extends Component {
  onDragEnd = result => {
    if (!result.destination) {
      return
    }
    this.props.setItems(reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    ))
  }

  componentDidMount() {
    this.props.ids && this.props.setItems(this.props.ids.map(id => this.props.data[id]).sort((a, b) => a.order - b.order))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.ids !== prevProps.ids) {
      this.props.setItems(this.props.ids.map(id => this.props.data[id]).sort((a, b) => a.order - b.order))
    }
    return true
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <Box>
                {this.props.items && this.props.items.map((item, index) => (
                  <Draggable
                    key={`key-${item.id}-${item.type ?? 'base'}`}
                    draggableId={`key-${item.id}-${item.type ?? 'base'}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <Index index={index + 1}/>

                        <ListItemText
                          disableTypography={this.props.disableTypography}
                          primary={this.props.primary && this.props.primary(item)}
                          secondary={this.props.secondary && this.props.secondary(item)}
                        />

                        {this.props.controls && this.props.controls(item)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}

export {DraggableList}