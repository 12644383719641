import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useConfig} from '../../layout/useConfig'

const useStyles = makeStyles({
  logo: p => ({
    background: `url(${p.logoImage}) no-repeat center center`,
    backgroundSize: 'contain',
    width: 90,
    height: 90,
    margin: 6
  }),
  logoSmall: p => ({
    background: `url(${p.logoSmallImage}) no-repeat center center`,
    backgroundSize: 'contain',
    width: 48,
    height: 42,
  }),
  text: p => ({
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${p.textImage})`,
    backgroundSize: 'contain',
    width: 80,
    height: 36,
  })
})

export const Logo = props => {
  const {login_logo, logo} = useConfig()
  const classes = useStyles({logoImage: login_logo, textImage: logo})

  switch (props.type) {
    case 'text':
      return <div className={classes.text}/>
    case 'logo':
    default:
      return <div className={classes.logo}/>
  }
}