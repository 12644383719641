import {AccountBox} from '@material-ui/icons'
import {PositionList} from './PositionList'
import {PositionEdit} from './PositionEdit'
import {PositionCreate} from './PositionCreate'

export default {
  name: 'infobot/position',
  icon: AccountBox,
  list: PositionList,
  edit: PositionEdit,
  create: PositionCreate,
};
