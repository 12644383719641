import {CallToAction} from '@material-ui/icons'
import {EditGuesser, ShowGuesser} from 'react-admin'
import {BotList} from './BotList'
import {BotEdit} from './BotEdit'
import {BotCreate} from './BotCreate'
import bot from './bot.svg'
import { ReactComponent as Github } from "./bot.svg";
import { SvgIcon } from '@material-ui/core';
import React from 'react'

export default {
  name: 'infobot/bot',
  icon: () =>  <Github />,
  list: BotList,
  // show: Bot,
  edit: BotEdit,
  create: BotCreate,
};
