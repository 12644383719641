import merge from 'lodash/merge'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'
import enMessages from '../i18n/en'
import russianMessages from 'ra-language-russian'
import ruMessages from '../i18n/ru'

const {ra: raRu, ...otherRuMessages } = ruMessages
const {ra: enRu, ...otherEnMessages } = enMessages

const messages = {
  ru: {
    ...merge(russianMessages, {ra: raRu}),
    ...otherRuMessages,
  },
  en: {
    ...merge(englishMessages, {ra: enRu}),
    ...otherEnMessages,
  },
}

function getDefaultLanguage() {
  let locale = localStorage.getItem('locale')

  if (!locale) {
    const rawLanguage = navigator.language
    const rawLocale = rawLanguage.match(/^([\w+]{2})-/)
    if (Array.isArray(rawLocale) && rawLocale[1]) {
      locale = rawLocale[1].toLowerCase()
    }
  }

  return locale ? locale : 'en'
}

const i18nProvider = polyglotI18nProvider(locale => messages[locale], getDefaultLanguage(), {allowMissing: false})

const languages = [
  {locale: 'ru', name: 'language.russian', native: 'Русский'},
  {locale: 'en', name: 'language.english', native: 'English'},
]

export default i18nProvider
export {languages}