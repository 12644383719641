import React from 'react'
import {useInput, SelectInput, ReferenceInput, useQuery, LinearProgress, useTranslate} from 'react-admin'
import Box from '@material-ui/core/Box'
import {JsonEditor} from '../JsonEditor/JsonEditor'
import {Field, useForm} from 'react-final-form'
import {useStore} from 'react-redux'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

const ScreenSelect = props => {
  const {value, goToScreen, setValue} = props
  const translate = useTranslate()

  if (value !== '{}') {
    const found =
      props.choices.find(screen => `{"iname": "${screen.iname}"}` === value)
      ??
      props.choices.find(screen => screen.id === goToScreen)

    if (found) {
      if (`{"iname": "${found.iname}"}` !== value) {
        setValue({
          action_args: `{"iname": "${found.iname}"}`,
          go_to_screen: found.id,
        })
      }
    } else {
      setValue({
        action_args: '{}',
        go_to_screen: null,
      })
    }
  }

  return (
    <>
      <Field name="action_args">
        {(field) => (
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-label">{translate('resources.infobot/screen.name', 1)}</InputLabel>
            <Select
              margin="dense"
              value={field.input.value}
              onChange={(_, el) => setValue({
                action_args: el.props.value,
                go_to_screen: el.props.id,
              })}
            >
              {props.choices.map(screen => {
                const key = `{"iname": "${screen.iname}"}`
                return <MenuItem id={screen.id} key={key} value={key}>{screen.iname}</MenuItem>
              })}
            </Select>
          </FormControl>
        )}
      </Field>
    </>
  )
}

export const Action = props => {
  const store = useStore()
  const form = useForm()
  const formState = form.getState()
  const translate = useTranslate()

  const {data, loading, error} = useQuery({
    type: 'getList',
    resource: 'infobot/action',
    payload: {
      filter: {},
      pagination: {page: 1, perPage: 10000},
      sort: {field: 'id'}
    },
  })

  useInput('action_args')

  if (loading) {
    return <LinearProgress/>
  }

  const action = store.getState().admin.resources['infobot/action'].data[formState.values.action]
  const isGoToScreen = action?.iname === 'go_to_screen'

  return (
    <>
      <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
        <Box flex={1}>
          <SelectInput
            fullWidth
            label={translate('resources.infobot/action.fields.action')}
            optionText="iname"
            source="action"
            choices={data}
          />
        </Box>
        {!props.fullWidth && <Box flex={1}/>}
      </Box>
      {action && (
        isGoToScreen ?
          (
            <Box key="screen" fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box flex={1}>
                <ReferenceInput perPage={10000} fullWidth source="go_to_screen" reference="infobot/screen">
                  <ScreenSelect
                    value={formState.values.action_args}
                    goToScreen={formState.values.go_to_screen}
                    setValue={value => {
                      form.change('action_args', value.action_args)
                      form.change('go_to_screen', value.go_to_screen)
                    }}
                  />
                </ReferenceInput>
              </Box>
              {!props.fullWidth && <Box flex={1}/>}
            </Box>
          ) : (
            <Box key="edit" fullWidth>
              <JsonEditor label={translate('resources.infobot/action.fields.action_args')}/>
            </Box>
          )
      )}
    </>
  )
}
