import React from 'react'
import {Edit, TextInput, BooleanInput, FormTab, TabbedForm, SelectInput, useTranslate} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {locales} from '../../../components/Profile'
import {QuestionList} from './tabs/QuestionList'
import {Action} from '../../../components/Action/Action'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 800
  },
}))

export const AnswerEdit = props => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Edit undoable={false} {...props} >
      <TabbedForm>
        <FormTab label={translate('resources.faq_bot_plugin/answer.tabs.main')}>
          <Box className={classes.plane} my={1} mx={2}>

            <Box fullWidth>
              <BooleanInput
                label={translate('resources.faq_bot_plugin/answer.fields.is_enabled')}
                source="is_enabled"
              />
            </Box>

            <Box fullWidth>
              <TextInput
                fullWidth
                label={translate('resources.faq_bot_plugin/answer.fields.text')}
                source="text"
                multiline
                rows="8"
                variant="outlined"
              />
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box flex={1}>
                <SelectInput
                  fullWidth
                  label={translate('resources.faq_bot_plugin/answer.fields.language')}
                  source="language"
                  choices={locales}
                />
              </Box>
              <Box flex={1}/>
            </Box>

            <Action/>
          </Box>
        </FormTab>

        <FormTab label={translate('resources.faq_bot_plugin/answer.tabs.questions')} path="questions">
          <Box className={classes.plane} m={1}>
            <QuestionList {...props} />
          </Box>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
