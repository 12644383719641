import React from 'react'
import {Route} from 'react-router-dom'
import {Charts} from './components/Charts/Charts'
import {Map} from './components/Map/Map'
import {KolesaQuest} from './components/KolesaQuest/KolesaQuest'

export default [
  <Route
    key="charts"
    path="/charts/:tab?"
    component={Charts}
  />,
  <Route
    key="map"
    path="/map/:tab?"
    component={Map}
  />,
  <Route
    path="/kolesa_quest"
    component={KolesaQuest}
    noLayout
  />
]