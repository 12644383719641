import React from 'react'
import {useCreateController, useTranslate, SimpleForm, useRefresh, useRedirect, ImageInput, ImageField} from 'react-admin'
import {Toolbar} from './Toolbar'

export const RouteImageCreate = props => {
  const resource = 'places_bot_plugin/routeimage'
  const refresh = useRefresh()
  const redirect = useRedirect()

  const controllerProps = useCreateController({
    ...props,
    resource,
    basePath: ''
  })

  const save = controllerProps.save
  controllerProps.save = (data, redirectTo, _a = {}) => {
    _a.onSuccess = () => {
      refresh()
      redirect(`/places_bot_plugin/route/${props.route}/image`)
    }
    save(data, redirectTo, _a)
  }

  return (
    <SimpleForm
      undoable={false}
      initialValues={{route: props.route}}
      basePath={controllerProps.basePath}
      record={controllerProps.record}
      save={controllerProps.save}
      version={controllerProps.version}
      resource={resource}
      toolbar={<Toolbar/>}
    >
      <ImageInput source="image|rawFile" label="" accept="image/*">
        <ImageField source="image" title="title"/>
      </ImageInput>
    </SimpleForm>
  )
}
