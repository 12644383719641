import React from 'react'
import {
  ReferenceManyField,
  Datagrid,
  TextField,
  DeleteButton,
  useTranslate,
} from 'react-admin'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import {Drawer, Button} from '@material-ui/core'
import {Route, useLocation, useHistory} from 'react-router-dom'
import {Edit, Close, Add} from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import {QuestionCreate} from './QuestionCreate'
import {QuestionEdit} from './QuestionEdit'

const useStyles = makeStyles(theme => ({
  drawer: {
    maxWidth: '100vw',
    width: 500,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  plane: {
    width: '100%',
    maxWidth: 800
  },
  imgContainer: {
    padding: 16,
    '& img': {
      height: '100%',
      width: '100%',
      maxWidth: 320,
      objectFit: 'contain',
      objectPosition: 'left',
      display: 'block',
      margin: 0,
    }
  },
  headerCell: {
    display: 'none',
  },
  rowCell: {
    padding: '6px !important',
    '&:first-child': {
      width: '100%',
    }
  },
  test: {
    paddingLeft: 4,
  }
}))

const EditButton = props => {
  const translate = useTranslate()
  const location = useLocation()
  const history = useHistory()

  return (
    <Button
      startIcon={<Edit/>}
      color="primary"
      onClick={() => history.push(`${location.pathname}?question=${props.record.id}`)}
    >
      {translate('ra.action.edit')}
    </Button>
  )
}

const QuestionGrid = props => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const translate = useTranslate()

  return (
    <>
      <Box m={1}>
        <Button
          startIcon={<Add/>}
          color="primary"
          onClick={() => history.push(`/faq_bot_plugin/answer/${props.id}/questions?question=create`)}
        >
          {translate('resources.faq_bot_plugin/answer.add_question')}
        </Button>
      </Box>
      <Datagrid
        {...props}
        fullWidth
        classes={{
          headerCell: classes.headerCell,
          rowCell: classes.rowCell,
        }}
      >
        <TextField fullWidth source="text"/>

        <EditButton {...props}/>
        <DeleteButton undoable={false} redirect={`/faq_bot_plugin/answer/${props.id}/questions`}/>
      </Datagrid>
    </>
  )
}

export const QuestionList = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const location = useLocation()
  const history = useHistory()

  const drawerClose = () => history.push(location.pathname)

  return (
    <Route path="/faq_bot_plugin/answer/:id/questions">
      {({match, location: {search}}) => {
        const urlParams = new URLSearchParams(search)
        if (!match) {
          if (urlParams.has('question')) {
            urlParams.delete('question')
            history.push(`${location.pathname}?${urlParams.toString()}`)
          }
          return null
        }
        let question = urlParams.get('question')
        question = Number.parseInt(question) ? Number.parseInt(question) : question

        return (
          <>
            <Box className={classes.plane}>
              <ReferenceManyField
                {...props}
                perPage={10000}
                className={classes.plane}
                label="Pew"
                filter={{answer: props.id}}
                reference="faq_bot_plugin/question"
              >
                <QuestionGrid id={props.id}/>
              </ReferenceManyField>
            </Box>

            <Drawer
              open={question}
              anchor="right"
              onClose={drawerClose}
              classes={{paper: classes.drawerPaper}}
            >
              <div className={classes.drawer}>
                {question === 'create' ? (
                  <>
                    <div className={classes.title}>
                      <Typography variant="h6">
                        {translate('resources.faq_bot_plugin/answer.add_question', 1)}
                      </Typography>
                      <IconButton onClick={drawerClose}>
                        <Close/>
                      </IconButton>
                    </div>
                    <QuestionCreate {...props} answer={props.id}/>
                  </>
                ) : (
                  <>
                    <div className={classes.title}>
                      <Typography variant="h6">
                        {translate('resources.faq_bot_plugin/answer.edit_question', 1)}
                      </Typography>
                      <IconButton onClick={drawerClose}>
                        <Close/>
                      </IconButton>
                    </div>
                    <QuestionEdit {...props} id={question} answer={props.id}/>
                  </>
                )}
              </div>
            </Drawer>
          </>
        )
      }}
    </Route>
  )
}
