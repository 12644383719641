import React, {useState} from 'react'
import {Edit, useRedirect, TextInput, BooleanInput, FormTab, TabbedForm, useDataProvider, useTranslate} from 'react-admin'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import {RoutePlaceEdit} from './tabs/RoutePlaceEdit'
import {RouteImageList} from './tabs/RouteImageList'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 800,
  },
  toolBar: {
    background: '#f5f5f5',
  }
}))

const updateRoutePlace = async (places, dataProvider) => {
  if (places && places.length) {
    const ids = []
    const data = {
      needDataMap: true,
    }
    places.forEach((place, index) => {
      ids.push(place.id)
      data[place.id] = {...place, order: index}
    })
    await dataProvider.updateMany('places_bot_plugin/routeplace', {ids, data})
  }
}

const updateRouteImages = async (images, dataProvider) => {
  if (images && images.length) {
    const ids = []
    const data = {
      needDataMap: true,
    }
    images.forEach((image, index) => {
      ids.push(image.id)
      data[image.id] = {...image, order: index}
      delete data[image.id].image
      delete data[image.id].description
    })
    await dataProvider.updateMany('places_bot_plugin/routeimage', {ids, data})
  }
}

export const RouteEdit = props => {
  const dataProvider = useDataProvider()
  const redirect = useRedirect()
  const [places, setPlaces] = useState([])
  const [images, setImages] = useState([])
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Edit undoable={false} {...props} >
      <TabbedForm initialValues={{name: '_'}} redirect={async (url) => {
        await Promise.all([
          updateRoutePlace(places, dataProvider),
          updateRouteImages(images, dataProvider),
        ])
        redirect(url)
      }}>
        <FormTab label={translate('resources.places_bot_plugin/route.tabs.main', 1)}>
          <Box className={classes.plane}>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="name_ru" label={translate('resources.places_bot_plugin/route.fields.name_ru', 1)}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="name_en" label={translate('resources.places_bot_plugin/route.fields.name_en', 1)}/>
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_ru" label={translate('resources.places_bot_plugin/route.fields.description_ru', 1)}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_en" label={translate('resources.places_bot_plugin/route.fields.description_en', 1)}/>
              </Box>
            </Box>

            <Box className={classes.plane} mx={2}>
              <BooleanInput source="can_choose_start_place" label={translate('resources.places_bot_plugin/route.fields.can_choose_start_place', 1)}/>
              <BooleanInput source="is_one_way_mode"  label={translate('resources.places_bot_plugin/route.fields.is_one_way_mode', 1)}/>
            </Box>
          </Box>
        </FormTab>

        <FormTab label={translate('resources.places_bot_plugin/route.tabs.images', 1)} path={'image'}>
          <RouteImageList {...props} images={images} setImages={setImages} />
        </FormTab>

        <FormTab label={translate('resources.places_bot_plugin/route.tabs.routes', 1)} path="place">
          <RoutePlaceEdit id={props.id} places={places} setPlaces={setPlaces}/>
        </FormTab>

      </TabbedForm>
    </Edit>
  )
}
