import React, {useEffect} from 'react'
import {PlacesMap} from '../Map/PlacesMap'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {useSetLocale} from 'ra-core'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
    top: 0,
  },
  toolBar: {
    flexGrow: 1,
    minHeight: 48,
    background: '#2196f3',
    justifyContent: 'center',
  },
  root: {
    margin: `48px 48px 48px 24px`,
  }
}))

export const KolesaQuest = (props) => {
  const setLocale = useSetLocale()
  const classes = useStyles()

  useEffect(() => {
    void async function(locale) {
      await setLocale(locale)
    }('ru')
  }, [])

  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography variant="h6">Экологический квест «Охота на колеса»</Typography>
        </Toolbar>
      </AppBar>

      <div className={classes.root}>
        <PlacesMap
          location={props.location}
          url={'/kolesa_quest'}
          editable={false}
          languages={false}
        />
      </div>
    </div>
  )
}