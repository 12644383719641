import {useEffect, useState} from 'react'
import {rawDataProvider} from '../providers/dataProvider'

export function useRawDataProvider(type, resource, params) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    void async function () {
      try {
        setLoading(true)
        const response = await rawDataProvider(type, resource, params)
        setData(response.data)
        setLoading(false)
      } catch (e) {
        setError(e)
        setLoading(false)
      }
    }()
  }, [])
  return {
    data,
    loading,
    error,
  }
}