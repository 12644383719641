import * as React from 'react'
import get from 'lodash/get'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import sanitizeRestProps from '../../utils/sanitizeRestProps'
import {useState} from 'react'
import {ImagePreview} from '../ImagePreview/ImagePreview'

const useStyles = makeStyles(
  {
    list: {
      display: 'flex',
      listStyleType: 'none',
    },
    image: {
      margin: '0.5rem',
      maxHeight: '10rem',
      '&:hover': {
        opacity: .8,
        cursor: 'pointer',
      }
    },
  },
  {name: 'RaImageField'}
)

const ImageFieldPreview = props => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    record,
    source,
    src,
    title,
    ...rest
  } = props
  const sourceValue = get(record, source)
  const [show, setShow] = useState(false)
  const classes = useStyles(props)
  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : (
      <div className={className} {...sanitizeRestProps(rest)} />
    )
  }

  if (Array.isArray(sourceValue)) {
    return (
      <ul
        className={`${classes.list} ${className}`}
        {...sanitizeRestProps(rest)}
      >
        {sourceValue.map((file, index) => {
          const fileTitleValue = get(file, title) || title
          const srcValue = get(file, src) || title

          return (
            <li key={index}>
              <img
                alt={fileTitleValue}
                title={fileTitleValue}
                src={srcValue}
                className={classes.image}
              />
            </li>
          )
        })}
      </ul>
    )
  }

  const titleValue = get(record, title) || title

  return (
    <div
      className={className}
      {...sanitizeRestProps(rest)}
      onClick={e => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
      }}
    >
      <ImagePreview src={sourceValue} show={show} onClose={() => setShow(false)}/>
      <img
        title={titleValue}
        alt={titleValue}
        src={sourceValue}
        className={classes.image}
        onClick={() => {
          setShow(true)
        }}
      />
    </div>
  )
}

ImageFieldPreview.displayName = 'ImageField'
ImageFieldPreview.defaultProps = {
  addLabel: true,
}

export default ImageFieldPreview