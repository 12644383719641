import React from 'react'
import {useCreateController, useTranslate, SimpleForm, useRefresh, useRedirect, TextInput} from 'react-admin'
import {Toolbar} from './Toolbar'

export const QuestionCreate = props => {
  const resource = 'faq_bot_plugin/question'
  const refresh = useRefresh()
  const translate = useTranslate()
  const redirect = useRedirect()

  const controllerProps = useCreateController({
    ...props,
    resource,
    basePath: ''
  })

  const save = controllerProps.save
  controllerProps.save = (data, redirectTo, _a = {}) => {
    _a.onSuccess = () => {
      refresh()
      redirect(`/faq_bot_plugin/answer/${props.answer}/questions`)
    }
    save(data, redirectTo, _a)
  }

  return (
    <SimpleForm
      undoable={false}
      initialValues={{answer: props.answer}}
      basePath={controllerProps.basePath}
      record={controllerProps.record}
      save={controllerProps.save}
      version={controllerProps.version}
      resource={resource}
      toolbar={<Toolbar/>}
    >
      <TextInput fullWidth source="text" label={translate('resources.faq_bot_plugin/answer.fields.text')}  multiline rows="8" variant="outlined"/>
    </SimpleForm>
  )
}
