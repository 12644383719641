import {VideoLabel} from '@material-ui/icons'
import {ListGuesser} from 'react-admin'
import {ScreenCreate} from './ScreenCreate';
import {ScreenEdit} from './ScreenEdit';
import {ScreenList} from './SceenList'

export default {
  name: 'infobot/screen',
  icon: VideoLabel,
  create: ScreenCreate,
  list: ScreenList,
  edit: ScreenEdit,
};
