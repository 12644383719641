import React from 'react'
import {List, Datagrid} from 'react-admin'
import ImageFieldPreview from '../../../components/ImageFieldPreview/ImageFieldPreview'

export const ImageWidgetList = props => {

  return (
    <List {...props} >
      <Datagrid>
        <ImageFieldPreview source="src" title="title" />
      </Datagrid>
    </List>
  )
}