import React from 'react'
import {Route} from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import {useRedirect} from 'react-admin'
import {WidgetEdit} from './WidgetEdit'

export const WidgetEditRoute = (props, widget) => {
  const screenURL = `/infobot/screen/${props.id}`
  const redirect = useRedirect()

  const drawerClose = () => redirect(screenURL)

  return (
    <React.Fragment key={widget.type}>
      <Route key={widget.type} path={`/infobot/screen/:screenID/${widget.type}/:id`}>
        {({match}) => {
          const isMatch = !!(match && match.params && match.params.id !== 'create')

          return (
            <>
              <Drawer
                open={isMatch}
                anchor="right"
                onClose={drawerClose}
              >
                {isMatch ? (
                  <WidgetEdit
                    {...props}
                    widget={widget}
                    onCancel={drawerClose}
                    widgetID={match.params.id}
                  />
                ) : null}
              </Drawer>
            </>
          )
        }}
      </Route>
    </React.Fragment>
  )
}