import React from 'react'
import {Create, TextInput, SimpleForm} from 'react-admin'

const validateUserCreation = (values) => {
  const errors = {}
  if (!values.iname) {
    errors.iname = ['The iname is required']
  }
  return errors
}

export const ScreenCreate = props => {
  return (
    <>
      <Create {...props}>
        <SimpleForm validate={validateUserCreation}>
          <TextInput source="iname"/>
        </SimpleForm>
      </Create>
    </>
  )
}