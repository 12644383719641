import React from 'react'
import {Create, ImageField, ImageInput, TextInput, SimpleForm} from 'react-admin'

export const ImageWidgetCreate = props => {
  const {screenID} = props.params ? props.params : {}
  const screen = screenID ? parseInt(screenID, 10) : null
  const redirect = screen ? `/infobot/screen/${screen}` : false

  return (
    <>
      <Create
        {...props}
        resource="infobot/imagewidget"
      >
        <SimpleForm
          initialValues={{screen}}
          redirect={redirect}
        >
          {!screen && <TextInput source="screen"/>}

          <ImageInput source="picture" label="Related pictures" accept="image/*">
            <ImageField source="src" title="title"/>
          </ImageInput>

        </SimpleForm>
      </Create>
    </>
  )
}