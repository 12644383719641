import { stringify } from 'query-string';
import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
    GET_MANY,
    GET_MANY_REFERENCE,
} from 'react-admin';
import i18nProvider from './i18nProvider'

function addHeaders(options) {
    // add csrf protection
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('X-CSRFToken', getCookie('csrftoken'));

    const token = localStorage.getItem('auth_token')
    if(token) {
        options.headers.set('Authorization', `Token ${token}`);
    }

    options.headers.set('Accept-Language', i18nProvider.getLocale())

    return options
}

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

/**
 * Maps react-admin queries to the default format of Django REST Framework
 */
const drfProvider = (apiUrl, httpClient=fetchUtils.fetchJson) => {

    /**
     * @param {String} type React-admin request type, e.g. 'GET_LIST'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params Request parameters. Depends on the request type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHttp = (type, resource, params) => {
        let url = "";
        let options = {};
        let formData = null
        let notProcessedFields = []

        if(params.data) {
            Object.entries(params.data).forEach(([key, value]) => {
                const match = key.match(/^(\w+)\|rawFile$/)
                if (!match) {
                    notProcessedFields.push(key)
                    return
                }
                if(!formData) {
                    formData = new FormData()
                }
                const originalKey = match[1]
                formData.append(originalKey, value.rawFile, value.rawFile.name)
            })
        }
        if(formData) {
            notProcessedFields.forEach(key => {
                if(params.data[key]) {
                    formData.append(key, params.data[key])
                }
            })
        }

        switch(type){
            case CREATE:
                url = `${apiUrl}/${resource}/`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}/`;
                break;
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const { filter } = params;
                const query = {
                    page,
                    page_size: perPage,
                    ordering: `${order === 'ASC' ? '' : '-'}${field}`,
                    ...filter
                };
                url = `${apiUrl}/${resource}/?${stringify(query)}`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const { filter, target, id } = params;
                const query = {
                    page,
                    page_size: perPage,
                    ordering: `${order === 'ASC' ? '' : '-'}${field}`,
                    ...filter,
                    [target]: id
                };
                url = `${apiUrl}/${resource}/?${stringify(query)}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}/`;
                options.method = 'PUT';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}/`;
                options.method = 'DELETE';
                break;
            default:
                throw new Error(`Unsupported Data Provider request type ${type}`);
        }

        if(formData) {
            options.body = formData
            options.headers = new Headers({})
        }

        return { url, options };
    }

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type React-admin request type, e.g. 'GET_LIST'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params Request parameters. Depends on the request type
     * @returns {Object} Data response
     */
    const convertHttpResponse = (response, type, resource, params) => {
        const { headers, json } = response;

        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if ('count' in json){
                    return { data: json.results, total: json.count }
                } else if (headers.has('content-range')) {
                    return {
                        data: json,
                        total: parseInt(
                            headers
                            .get('content-range')
                            .split('/')
                            .pop(),
                            10
                        ),
                    };
                } else if ('detail' in json && json.detail === 'Invalid page.') {
                    return { data: [], total: 0 }
                } else {
                    throw new Error(
                        'The total number of results is unknown. The DRF data provider ' +
                        'expects responses for lists of resources to contain this ' +
                        'information to build the pagination. If you\'re not using the ' +
                        'default PageNumberPagination class, please include this ' +
                        'information using the Content-Range header OR a "count" key ' +
                        'inside the response.'
                    );
                }
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            case DELETE:
                return { data: params.previousData };
            default:
                return { data: json };
        }
    }

    /**
     * @param {String} type React-admin request type, e.g. 'GET_LIST'
     * @param {string} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        /**
         * Split GET_MANY, UPDATE_MANY and DELETE_MANY requests into multiple promises,
         * since they're not supported by default.
         */
        switch (type) {
            case 'CUSTOM_GET':
                return httpClient(resource, addHeaders({method: 'GET', data: params}))
            case 'CUSTOM_POST':
                let body = JSON.stringify(params)
                let headers = null
                let formData = null
                let notProcessedFields = []
                if(params) {
                    Object.entries(params).forEach(([key, value]) => {
                        const match = key.match(/^(\w+)\|rawFile$/)
                        if (!match) {
                            notProcessedFields.push(key)
                            return
                        }
                        if(!formData) {
                            formData = new FormData()
                        }
                        const originalKey = match[1]
                        formData.append(originalKey, params[key], params[key].name)
                    })
                }
                if(formData) {
                    notProcessedFields.forEach(key => {
                        if(params[key]) {
                            formData.append(key, params[key])
                        }
                    })
                    body = formData
                    headers = new Headers({})
                }
                return httpClient(resource, addHeaders({method: 'POST', body, headers}))
            case 'CUSTOM_PUT':
                return httpClient(resource, addHeaders({method: 'PUT', body: JSON.stringify(params)}))
            case GET_MANY:
                return Promise.all(
                    params.ids.map(id =>
                        httpClient(`${apiUrl}/${resource}/${id}/`, addHeaders({
                              method: 'GET'
                        }))
                    )
                ).then(responses => ({
                    data: responses.map(response => response.json),
                }));
            case UPDATE_MANY:
                /* todo: mapping data? */
                const needDataMap = params.data.needDataMap && params.ids.every(id => params.data[id])
                return Promise.all(
                    params.ids.map(id =>
                        httpClient(`${apiUrl}/${resource}/${id}/`, addHeaders({
                            method: 'PUT',
                            body: JSON.stringify(needDataMap ? params.data[id] :params.data),
                        }))
                    )
                ).then(responses => ({
                    data: responses.map(response => response.json),
                }));
            case DELETE_MANY:
                return Promise.all(
                    params.ids.map(id =>
                        httpClient(`${apiUrl}/${resource}/${id}/`, addHeaders({
                            method: 'DELETE',
                        }))
                    )
                ).then(responses => ({
                    data: responses.map(response => response.json),
                }));
            default:
                break;
        }

        const { url, options } = convertDataRequestToHttp(type, resource, params);

        addHeaders(options)

        return httpClient(url, options).then(response => convertHttpResponse(response, type, resource, params));
    }
}

export default drfProvider;
