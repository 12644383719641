import * as React from 'react'
import {FunctionComponent, useCallback} from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormGroup, {FormGroupProps} from '@material-ui/core/FormGroup'
import Switch, {SwitchProps} from '@material-ui/core/Switch'
import {FieldTitle, useInput, InputProps} from 'ra-core'

const UndefinedBooleanInput = ({
                                 format,
                                 label,
                                 fullWidth,
                                 helperText,
                                 onBlur,
                                 onChange,
                                 onFocus,
                                 options,
                                 disabled,
                                 parse,
                                 resource,
                                 source,
                                 validate,
                                 alwaysOn,
                                 allowEmpty,
                                 ...rest
                               }) => {
  const {
    id,
    input: {onChange: finalFormOnChange, type, value, ...inputProps},
    isRequired,
    meta: {error, touched},
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'checkbox',
    validate,
    ...rest,
  })

  const handleChange = useCallback(
    (event, value) => {
      finalFormOnChange(value ? value : undefined)
    },
    [finalFormOnChange]
  )

  return (
    <FormGroup {...rest}>
      <FormControlLabel
        control={
          <Switch
            id={id}
            color="primary"
            onChange={handleChange}
            {...inputProps}
            {...options}
            disabled={disabled}
          />
        }
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
      />
      <FormHelperText error={!!error}>
        {/*<InputHelperText*/}
        {/*  touched={touched}*/}
        {/*  error={error}*/}
        {/*  helperText={helperText}*/}
        {/*/>*/}
      </FormHelperText>
    </FormGroup>
  )
}

export default UndefinedBooleanInput