import React from 'react'
import {LiveHelp} from '@material-ui/icons'
import {AnswerEdit} from './AnswerEdit'
import {AnswerList} from './AnswerList'
import {AnswerCreate} from './AnswerCreate'

export default {
  name: 'faq_bot_plugin/answer',
  icon: LiveHelp,
  list: AnswerList,
  create: AnswerCreate,
  edit: AnswerEdit,
}