import React from 'react'
import {
  Create,
  SimpleForm,
  useTranslate,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  plane: {
    width: '100%',
    maxWidth: 800
  },
})

const validate = (values) => {
  const errors = {}
  if (!values.iname) {
    errors.iname = ['The iname is required']
  }
  if (!values.end_screen) {
    errors.end_screen = ['The end screen is required']
  }
  return errors
}

export const PollCreate = props => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <>
      <Create {...props}>
        <SimpleForm validate={validate} initialValues={{answer: props.answer}}>
          <Box className={classes.plane}>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="iname" label={translate('resources.poll_bot_plugin/poll.fields.iname')}/>
              </Box>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}} />
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_ru"
                           label={translate('resources.poll_bot_plugin/poll.fields.description_ru')}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_en"
                           label={translate('resources.poll_bot_plugin/poll.fields.description_en')}/>
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <ReferenceInput
                  perPage={10000}
                  source="end_screen"
                  reference="infobot/screen"
                  label={translate('resources.poll_bot_plugin/poll.fields.end_screen')}
                >
                  <SelectInput fullWidth optionText="iname"/>
                </ReferenceInput>
              </Box>
              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}/>
            </Box>
          </Box>
        </SimpleForm>
      </Create>
    </>
  )
}