import React from 'react'
import {
  ReferenceManyField,
  DeleteButton,
  useTranslate,
  ImageField,
} from 'react-admin'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import {Drawer, Button} from '@material-ui/core'
import {Route, useLocation, useHistory} from 'react-router-dom'
import {Edit, Close, Add} from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import {RouteImageCreate} from './RouteImageCreate'
import {RouteImageEdit} from './RouteImageEdit'
import {DraggableList} from '../../../../components/DraggableList/DraggableList'
import ImageFieldPreview from '../../../../components/ImageFieldPreview/ImageFieldPreview'

const useStyles = makeStyles(theme => ({
  drawer: {
    maxWidth: '100vw',
    width: 500,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  plane: {
    width: '100%',
    maxWidth: 800
  },
  imgContainer: {
    padding: 16,
    '& img': {
      height: '100%',
      width: '100%',
      maxWidth: 320,
      objectFit: 'contain',
      objectPosition: 'left',
      display: 'block',
      margin: 0,
    }
  },
  headerCell: {
    display: 'none',
  },
  rowCell: {
    padding: '6px !important'
  },
}))

const ImageEditButton = props => {
  const translate = useTranslate()
  const location = useLocation()
  const history = useHistory()
  return (
    <Button
      startIcon={<Edit/>}
      color="primary"
      onClick={() => history.push(`${location.pathname}?image=${props.id}`)}
    >
      {translate('ra.action.edit')}
    </Button>
  )
}

export const RouteImageList = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const location = useLocation()
  const history = useHistory()

  const drawerClose = () => history.push(location.pathname)

  return (
    <Route path="/places_bot_plugin/route/:id/image">
      {({match, location: {search}}) => {
        const urlParams = new URLSearchParams(search)
        if (!match) {
          if (urlParams.has('image')) {
            urlParams.delete('image')
            history.push(`${location.pathname}?${urlParams.toString()}`)
          }
          return null
        }
        let image = urlParams.get('image')
        image = Number.parseInt(image) ? Number.parseInt(image) : image

        return (
          <>
            <Box className={classes.plane}>
              <Box m={1}>
                <Button
                  startIcon={<Add/>}
                  color="primary"
                  onClick={() => history.push(`/places_bot_plugin/route/${props.id}/image?image=create`)}
                >
                 {translate('resources.places_bot_plugin/route.add_image', 1)}
                </Button>
              </Box>

              <ReferenceManyField
                {...props}
                perPage={10000}
                className={classes.plane}
                label="Pew"
                filter={{route: props.id}}
                reference="places_bot_plugin/routeimage"
                target={'1'}
              >
                <DraggableList
                  ids={props.ids}
                  data={props.data}
                  id={props.id}
                  items={props.images}
                  setItems={props.setImages}
                  disableTypography
                  primary={null}
                  secondary={item => (
                    <ImageFieldPreview className={classes.imgContainer} record={{src: item.image}} source="src" title="title"/>
                  )}
                  controls={item =>
                    <>
                      <ImageEditButton id={item.id}/>
                      <DeleteButton
                        style={{flexShrink: 0, margin: `0 16px`}}
                        record={item}
                        resource='places_bot_plugin/routeimage'
                        undoable={false}
                        redirect={`/places_bot_plugin/route/${props.id}/image`}
                      />
                    </>
                  }
                />
              </ReferenceManyField>
            </Box>

            <Drawer
              open={!!image}
              anchor="right"
              onClose={drawerClose}
              classes={{paper: classes.drawerPaper}}
            >
              <div className={classes.drawer}>
                {image === 'create' ? (
                  <>
                    <div className={classes.title}>
                      <Typography variant="h6">
                        {translate('resources.places_bot_plugin/place.add_image', 1)}
                      </Typography>
                      <IconButton onClick={drawerClose}>
                        <Close/>
                      </IconButton>
                    </div>

                    <RouteImageCreate {...props} route={props.id}/>
                  </>
                ) : (
                  <>
                    <div className={classes.title}>
                      <Typography variant="h6">
                        {translate('resources.places_bot_plugin/place.change_image', 1)}
                      </Typography>
                      <IconButton onClick={drawerClose}>
                        <Close/>
                      </IconButton>
                    </div>

                    <RouteImageEdit {...props} route={props.id} id={image}/>
                  </>
                )}
              </div>
            </Drawer>
          </>
        )
      }}
    </Route>
  )
}
