import {LinearScale} from '@material-ui/icons'
import {RouteEdit} from './routeEdit'
import {RouteList} from './routeList'
import {RouteCreate} from './RouteCreate'

export default {
  name: 'places_bot_plugin/route',
  icon: LinearScale,
  list: RouteList,
  edit: RouteEdit,
  create: RouteCreate,
};
