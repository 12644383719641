import React from 'react'
import {AppBar} from 'react-admin'
import MyUserMenu from './UserMenu'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {Logo} from '../components/Logo/Logo'


const useStyles = makeStyles(theme => ({
  title: {
    flex: `1 0`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  space: {
    flex: 1,
  },
}));

const CustomAppBar = props => {
  const classes = useStyles();

  return (
    <AppBar {...props} userMenu={<MyUserMenu/>}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />

      <Logo type="text" />
      <span className={classes.space} />
    </AppBar>
  )
}

export default CustomAppBar
