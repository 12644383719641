import React from 'react'
import {Create, TextInput, NumberInput, SimpleForm} from 'react-admin'
import {parse} from 'query-string'

export const ButtonWidgetCreate = props => {
  let {screen} = parse(props.location.search)
  screen = screen ? parseInt(screen, 10) : null
  const redirect = screen ? `/infobot/screen/${screen}` : false

  return (
    <>
      <Create {...props}>
        <SimpleForm
          initialValues={{text: '_', screen}}
          redirect={redirect}
        >
      {!screen && <TextInput source="screen"/>}
          <TextInput source="iname"/>
          <TextInput source="iname"/>
          <TextInput source="text_ru"/>
          <TextInput source="text_en"/>
          <TextInput source="action_args"/>
          <TextInput source="screen"/>
          <TextInput source="go_to_screen"/>
          <TextInput source="action"/>
        </SimpleForm>
      </Create>
    </>
  )
}
