import React from 'react'
import {
  List, Datagrid, TextField, Filter, DateField, ReferenceField,
  DateInput, TextInput, ReferenceInput, AutocompleteInput,
  useTranslate, NullableBooleanInput
} from 'react-admin'
import RateField from '../../../components/RateField/RateField'
import i18nProvider, {languages} from '../../../providers/i18nProvider'


const PostFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <DateInput source="rated_at__gte" alwaysOn />
      <DateInput source="rated_at__lt" alwaysOn />

      <ReferenceInput alwaysOn source="place" reference="places_bot_plugin/place" perPage={1000} allowEmpty>
        <AutocompleteInput optionText="name" emptyText={translate('resources.places_bot_plugin/userplaceratereview.all_places')} allowEmpty />
      </ReferenceInput>
    </Filter>
  )
};


export const reviewList = props => (
  <List perPage={25} filters={<PostFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
       <DateField showTime={true} source="rated_at" />
        <TextField sortable={false} source="channel" />
        <TextField sortable={false} source="bot_user_display_name" />
        <TextField sortable={false} source="text" />
        <RateField sortable={false} source="rate" is_like_source="is_like" />
        <ReferenceField sortable={false} source="place" reference="places_bot_plugin/place" >
           <TextField source="name" />
        </ReferenceField>
    </Datagrid>
  </List>
)
