import React, {useEffect, useRef, useState} from 'react'
import {YandexMap} from '../YandexMap/YandexMap'
import {useRedirect, useTranslate} from 'ra-core'
import {Drawer, makeStyles} from '@material-ui/core'
import {Route} from 'react-router-dom'
import {PlacesMapEdit} from './PlacesMapEdit'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import {useRawDataProvider} from '../../utils/useRawDataProvider'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 16,
    width: '100%',
  },
  filter: {
    padding: 16,
    width: '100%',
    minHeight: 65,
  },
  map: {
    margin: 0,
    padding: 0,
    minHeight: 420,
    [theme.breakpoints.up('md')]: {
      minHeight: `calc(100vh - 300px)`,
    },
  },
  drawerPaper: {
    zIndex: 100,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 300,
    // maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))

export const PlacesMap = props => {
  const [geoObjects, setGeoObjects] = useState({})
  const [filterTags, setFilterTags] = React.useState([])
  const translate = useTranslate()
  const redirect = useRedirect()
  const classes = useStyles()

  const editable = props.editable
  const languages = props.languages
  const mapURL = props.url

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search)
    const tags = urlParams.get('tags')
    if (tags) {
      setFilterTags(urlParams.get('tags').split(',').map(i => Number(i)))
    } else {
      setFilterTags([])
    }
  }, [props.location.search])

  const tagsToURL = (tags) => {
    if (!tags.length) {
      return ``
    }
    return `?tags=${tags.join(',')}`
  }

  const drawerClose = () => redirect(`${mapURL}${tagsToURL(filterTags)}`)

  const {data, loading} = useRawDataProvider('GET_LIST', 'places_bot_plugin/place', {
    filter: {},
    pagination: {page: 1, perPage: 10000},
    sort: {field: 'id'}
  })

  const {data: dataTypes, loading: loadingType} = useRawDataProvider('GET_LIST', 'places_bot_plugin/placetype', {
    filter: {},
    pagination: {page: 1, perPage: 10000},
    sort: {field: 'id'}
  })

  const {data: dataTags, loading: loadingTags} = useRawDataProvider('GET_LIST', 'places_bot_plugin/placetag', {
    filter: {},
    pagination: {page: 1, perPage: 10000},
    sort: {field: 'id'}
  })

  const refs = useRef({filterTags, drawerClose})

  refs.current.filterTags = filterTags
  refs.current.drawerClose = drawerClose

  const init = (map, ymaps) => {
    const geoObjects = {}

    const defaultType = {
      preset: 'islands#blueDotIcon',
      iconColor: '#2196f3',
      iconImageSize: [34, 41],
      iconImageOffset: [-17, -41],
    }

    data.forEach(place => {
      if (!place.latitude || !place.longitude) {
        return
      }
      let type = dataTypes.find(type => type.id === place.place_type) ?? defaultType
      if (!type.image || !type.image_selected) {
        type = {
          ...defaultType,
          iconColor: type.color ? type.color : defaultType.iconColor,
        }
      } else {
        type = {
          iconLayout: 'default#image',
          iconImageHref: type.image,
          iconImageSize: [type.image_size_width, type.image_size_height],
          iconImageOffset: [type.image_offset_x, type.image_offset_y],
        }
      }

      const geoObject = new ymaps.Placemark([place.latitude, place.longitude], {
        hintContent: place.name,
      }, type)
      geoObject.customeType = type

      geoObject.events.add('click', function (e) {
        Object.entries(geoObjects).forEach(([_, geoObject]) => {
          e.get('target').options.set('iconImageHref', type.image)
        })
        e.get('target').options.set('iconImageHref', type.image_selected)
        redirect(`${mapURL}/${place.id}${tagsToURL(refs.current.filterTags)}`)
      })

      map.geoObjects.add(geoObject)
      geoObjects[place.id] = geoObject
    })

    switch (Object.keys(geoObjects).length) {
      case 0:
        break
      case 1:
        const geoObject = geoObjects[Object.keys(geoObjects)[0]]
        map.setCenter(geoObject.geometry.getCoordinates(), 12)
        break
      default:
        map.setBounds(map.geoObjects.getBounds())
    }

    setGeoObjects(geoObjects)
  }

  return (
    <Route path={`${mapURL}/:id`}>
      {({match}) => {
        const place = match ? data?.find(place => place.id.toString() === match.params.id.toString()) : null

        const update = (map, ymaps) => {
          const filterTags = refs.current.filterTags
          Object.entries(geoObjects).forEach(([id, geoObject]) => {
            if (!filterTags.length) {
              return geoObject.options.set('visible', true)
            }
            const p = data.find(place => place.id.toString() === id.toString())
            let visible = p.tags.some(tag => filterTags.includes(tag))
            geoObject.options.set('visible', visible)
            if (place && !visible && place.id.toString() === id.toString()) {
              refs.current.drawerClose()
            }
          })
          Object.entries(geoObjects).forEach(([_, geoObject]) => {
            if (geoObject.customeType.image) {
              geoObject.options.set('iconImageHref', geoObject.customeType.image)
            } else {
              geoObject.options.set('preset', 'islands#blueIcon')
            }
          })
          if (place) {
            if (geoObjects[place.id].customeType.image) {
              geoObjects[place.id].options.set('iconImageHref', geoObjects[place.id].customeType.image_selected)
            } else {
              geoObjects[place.id].options.set('preset', 'islands#blueDotIcon')
            }
          }
        }

        return (
          <>
            <div className={classes.filter}>
              <FormControl className={classes.formControl}>
                <InputLabel id="mutiple-chip-label">
                  {translate('resources.places_bot_plugin/placetag.name', 2)}
                </InputLabel>
                <Select
                  variant="filled"
                  labelId="mutiple-chip-label"
                  id="mutiple-chip"
                  multiple
                  value={filterTags.filter(id => dataTags && dataTags.find(tag => tag.id === id))}
                  onChange={e => redirect(`${props.location.pathname}${tagsToURL(e.target.value)}`)}
                  input={<Input id="select-multiple-chip"/>}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((id) => (
                        <Chip key={id} label={dataTags.find(tag => tag.id === id).name} className={classes.chip}/>
                      ))}
                    </div>
                  )}
                  // MenuProps={MenuProps}
                >
                  {dataTags && dataTags.map(tag => (
                    <MenuItem key={tag.id} value={tag.id} style={{}}>
                      {tag.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className={classes.root}>
              {!loading && !loadingType && <YandexMap className={classes.map} init={init} update={update}/>}
            </div>

            <Drawer
              variant="persistent"
              open={!!place}
              anchor="right"
              onClose={drawerClose}
              classes={{paper: classes.drawerPaper}}
            >
              {place && (
                <PlacesMapEdit
                  key={`place-${place.id}`}
                  place={place}
                  onClose={drawerClose}
                  editable={editable}
                  languages={languages}
                />
              )}
            </Drawer>
          </>
        )
      }}
    </Route>
  )
}