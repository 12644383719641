import {CallToAction} from '@material-ui/icons'
import {ListGuesser} from 'react-admin'
import {ButtonWidgetCreate} from './ButtonWidgetCreate'
import {ButtonWidgetEdit} from './ButtonWidgetEdit'

export default {
  icon: CallToAction,
  //list: ButtonWidgetList,
  show: ListGuesser,
  create: ButtonWidgetCreate,
  edit: ButtonWidgetEdit,
};
