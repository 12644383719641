import React, {Component, useState} from 'react'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import Card from '@material-ui/core/Card'
import {makeStyles} from '@material-ui/core/styles'
import {ImagePreview} from '../ImagePreview/ImagePreview'
import CloseIcon from '@material-ui/icons/Close'
import {InsertDriveFileOutlined} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: 12,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  card: {
    background: '#f0f0f0',
    flexBasis: 140,
    maxWith: 200,
    minWidth: 140,
    flexGrow: 1,
    margin: 8,
  },
  fake: {
    margin: '0 8px',
    height: 0,
    visibility: 'hidden',
  },
  delete: {
    position: 'absolute',
    background: 'rgba(255,255,255,.2)',
    top: 8,
    right: 8,
  },
  file: {
    display: 'flex',
    flexDirection: 'column',
    height: 108,
    padding: 16,
  },
  logo: {
    fontSize: 64,
    color: 'rgba(128, 128, 128, 1)',
  },
  exe: {
    position: 'absolute',
    width: 'calc(100% - 32px)',
    textAlign: 'center',
    top: 48,
    fontSize: 12,
    color: 'rgba(128, 128, 128, 1)',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  name: {
    textAlign: 'center',
    paddingTop: 6,
    fontSize: 14,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  size: {
    textAlign: 'center',
    color: 'rgba(128, 128, 128, 1)',
    fontSize: 11,
  }
}))

const regExp = /^(.*)\.(\w+)$/

const File = (props) => {
  const classes = useStyles()
  const {index, item, onRemoveClick} = props

  let file = item.file.name
  let extension = ''

  const match = regExp.exec(item.file.name)
  if (match) {
    file = match[1]
    extension = match[2]
  }

  let size = null
  let sizeUnit = null

  const SizeLength = item.file.size.toString().length
  switch (SizeLength) {
    case 0:
    case 1:
    case 2:
    case 3:
      size = item.file.size
      sizeUnit = 'Byte'
      break
    case 4:
    case 5:
    case 6:
      size = ((item.file.size / 1e2)|0) / 10
      sizeUnit = 'KB'
      break
    default:
      size = ((item.file.size / 1e5)|0) / 10
      sizeUnit = 'MB'
  }

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <IconButton
          size="small"
          className={classes.delete}
          onClick={() => onRemoveClick(index)}
        >
          <CloseIcon style={{fontSize: 24}}/>
        </IconButton>
        <div className={classes.file}>
          <div style={{textAlign: 'center'}}>
            <InsertDriveFileOutlined className={classes.logo}/>
            <div className={classes.exe}>{extension.slice(0, 3)}</div>
          </div>
          <div className={classes.name}>
            {file}
          </div>
          <div className={classes.size}>
            {size} {sizeUnit}
          </div>
        </div>
      </CardActionArea>
    </Card>
  )
}

const Image = (props) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)
  const {index, item, onRemoveClick} = props

  return (
    <Card className={classes.card}>
      <ImagePreview src={item.base64} show={show} onClose={() => setShow(false)}/>
      <CardActionArea>
        <IconButton
          size="small"
          className={classes.delete}
          onClick={() => onRemoveClick(index)}
        >
          <CloseIcon style={{fontSize: 24}}/>
        </IconButton>
        <CardMedia
          className={classes.image}
          component="img"
          height="140"
          src={item.base64}
          onClick={() => setShow(true)}
        />
      </CardActionArea>
    </Card>
  )
}

export const UploadPreview = props => {
  const classes = useStyles()
  const {items, onRemoveClick} = props

  return (
    <div className={classes.root}>
      {items.map((item, index) => item.file.type.includes('image') ?
        <Image key={index} index={index} item={item} onRemoveClick={onRemoveClick}/>
        :
        <File key={index} index={index} item={item} onRemoveClick={onRemoveClick}/>
      )}
      <div className={`${classes.card} ${classes.fake}`}/>
      <div className={`${classes.card} ${classes.fake}`}/>
    </div>
  )
}
