import React from 'react'
import {Create, TextInput, SimpleForm} from 'react-admin'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 800
  },
}))

export const PlaceTagCreate = props => {
  const classes = useStyles()

  return (
    <>
      <Create {...props}>
        <SimpleForm initialValues={{name: '_'}}>

          <Box className={classes.plane}>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="name_ru"/>
              </Box>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>

              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="name_en"/>
              </Box>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>

              </Box>
            </Box>

          </Box>

        </SimpleForm>
      </Create>
    </>
  )
}