import React from 'react'
import {Create, TextInput, SimpleForm, SelectInput, useTranslate} from 'react-admin'
import {locales} from '../../../components/Profile'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 800
  },
}))

export const AnswerCreate = props => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <>
      <Create {...props}>
        <SimpleForm>
          <Box className={classes.plane}>
            <Box fullWidth>
              <SelectInput source="language" choices={locales} label={translate('resources.faq_bot_plugin/answer.fields.language')}/>
            </Box>

            <Box fullWidth>
              <TextInput fullWidth source="text" multiline rows="6" variant="outlined" label={translate('resources.faq_bot_plugin/answer.fields.text')}/>
            </Box>
          </Box>
        </SimpleForm>
      </Create>
    </>
  )
}
