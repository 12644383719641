import React from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom'
import Card from '@material-ui/core/Card'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {DailyStats} from './blocks/DailyStats'
import {RoutesVisits} from './blocks/RoutesVisits'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import {useTranslate} from 'react-admin'
import {ScreenStats} from './blocks/ScreenStats'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 16,
    width: '100%',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}))

function TabPanel(props) {
  const {children, value, index, ...other} = props
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && (
        React.cloneElement(props.children)
      )}
    </div>
  )
}

const tabs = {
  'channel': 0,
  'routes': 1,
  'screens': 2,
  0: 'channel',
  1: 'routes',
  2: 'screens',
}

export const Charts = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const translate = useTranslate()
  const {tab} = match.params

  if (!tab) {
    history.push(`/charts/${tabs[0]}`)
    return null
  }

  const handleChange = (event, newValue) => {
    history.push(tabs[newValue])
  }

  return (
    <Box mt={2}>
      <Card>
        <Tabs value={tabs[tab]} onChange={handleChange}>
          <Tab label={translate('charts.channel.title')} id={0}/>
          <Tab label={translate('charts.routes.title')} id={1}/>
          <Tab label={translate('charts.screens.title')} id={2}/>
        </Tabs>
        <Divider light/>

        <TabPanel value={tabs[tab]} index={0}>
          <DailyStats/>
        </TabPanel>

        <TabPanel value={tabs[tab]} index={1}>
          <RoutesVisits/>
        </TabPanel>

        <TabPanel value={tabs[tab]} index={2}>
          <ScreenStats />
        </TabPanel>
      </Card>
    </Box>
  )
}

