import React from 'react'
import {Create, TextInput, SimpleForm, BooleanInput, useTranslate} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 800,
  },
}))

export const PlaceCreate = props => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <>
      <Create {...props}>
        <SimpleForm initialValues={{name: '_'}}>
          <Box className={classes.plane}>
            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="name_ru" label={translate('resources.places_bot_plugin/place.fields.name_ru')} />
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="name_en" label={translate('resources.places_bot_plugin/place.fields.name_en')} />
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="link" label={translate('resources.places_bot_plugin/place.fields.link')}/>
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_ru" label={translate('resources.places_bot_plugin/place.fields.description_ru')}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_en" label={translate('resources.places_bot_plugin/place.fields.description_en')}/>
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="address_ru" label={translate('resources.places_bot_plugin/place.fields.address_ru')}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="address_en" label={translate('resources.places_bot_plugin/place.fields.address_en')}/>
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="check_position_text_ru" label={translate('resources.places_bot_plugin/place.fields.check_position_text_ru')}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="check_position_text_en" label={translate('resources.places_bot_plugin/place.fields.check_position_text_en')}/>
              </Box>
            </Box>
          </Box>

          <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
            <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
              <BooleanInput source="is_default" label={translate('resources.places_bot_plugin/place.fields.is_default')}/>
              <BooleanInput source="is_transit" label={translate('resources.places_bot_plugin/place.fields.is_transit')}/>
            </Box>
          </Box>
        </SimpleForm>
      </Create>
    </>
  )
}
