import React from 'react'
import {Create, SimpleForm, TextInput, useTranslate, BooleanInput, ReferenceInput, SelectInput} from 'react-admin'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 800,
  },
}))

const choices = [
  {id: 'native', name: 'resources.infobot/bot.fields.maps_system_values.native'},
  {id: 'yandex', name: 'resources.infobot/bot.fields.maps_system_values.yandex'},
]

export const BotCreate = props => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Create {...props}>
      <SimpleForm initialValues={{}}>
        <Box className={classes.plane} >
          <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
            <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
              <TextInput
                fullWidth
                source="iname"
                label={translate('resources.infobot/bot.fields.iname', 1)}
              />

              <ReferenceInput
                perPage={10000}
                fullWidth
                source="start_screen"
                reference="infobot/screen"
                label={translate('resources.infobot/bot.fields.start_screen', 1)}
              >
                <SelectInput optionText="iname"/>
              </ReferenceInput>

              <ReferenceInput
                perPage={10000}
                fullWidth
                source="menu_screen"
                reference="infobot/screen"
                label={translate('resources.infobot/bot.fields.menu_screen', 1)}
              >
                <SelectInput optionText="iname"/>
              </ReferenceInput>

              <TextInput
                label={translate('resources.infobot/bot.fields.telegram_token', 1)}
                fullWidth
                source="telegram_token"
              />

              <SelectInput
                label={translate('resources.infobot/bot.fields.maps_system', 1)}
                fullWidth
                source="maps_system"
                choices={choices}
              />

            </Box>

            <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}/>
          </Box>

          <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
            <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>

              <BooleanInput
                label={translate('resources.infobot/bot.fields.use_nlu_as_default_handler', 1)}
                fullWidth
                source="use_nlu_as_default_handler"
              />
            </Box>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}
