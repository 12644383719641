import React from 'react'
import {Create, TextInput, SimpleForm} from 'react-admin'
import {parse} from 'query-string'

export const HtmlWidgetCreate = props => {
  let {screen} = parse(props.location.search)
  screen = screen ? parseInt(screen, 10) : null
  const redirect = screen ? `/infobot/screen/${screen}` : false

  return (
    <>
      <Create {...props} >
        <SimpleForm
          initialValues={{html: '_', screen}}
          redirect={redirect}
        >
          <TextInput source="iname"/>
          {!screen && <TextInput source="screen"/>}
          <TextInput fullWidth multiline rows="8" variant="outlined" source="html_ru"/>
          <TextInput fullWidth multiline rows="8" variant="outlined" source="html_en"/>
        </SimpleForm>
      </Create>
    </>
  )
}