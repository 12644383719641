import {Ballot} from '@material-ui/icons'
import {PollList} from './PollList'
import {PollCreate} from './PollCreate'
import {PollEdit} from './PollEdit'

export default {
  name: 'poll_bot_plugin/poll',
  icon: Ballot,
  create: PollCreate,
  list: PollList,
  edit: PollEdit,
}
