import React, {useState} from 'react'
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  ReferenceArrayInput,
  BooleanInput,
  useRedirect,
  useTranslate,
} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import dataProvider from '../../../providers/dataProvider'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 400
  },
}))

const InjectFields = props => {
  const classes = useStyles()
  const redirect = useRedirect()
  const [isOperator, setIsOperator] = useState(!!props.record.chat_operator_screen_name)
  props.record.is_operator = isOperator

  const save = props.save
  const injectSave = async (data, redirectTo, _a = {}) => {
    data.username = data.email
    data.is_staff = true
    data.is_superuser = true
    _a.onSuccess = async (res) => {
      if (data.is_operator) {
        await dataProvider('CREATE', 'chat_bot_plugin/chatoperator', {
          data: {
            screen_name: res.data.chat_operator_screen_name ? res.data.chat_operator_screen_name : '',
            user: res.data.id,
          }
        })
      }
      //refresh()
      redirect(`/infobot/user`)
    }
    save(data, redirectTo, _a)
  }

  const translate = useTranslate()

  return (
    <SimpleForm {...props} save={injectSave}>
      <Box className={classes.plane} m={1}>
          <Box fullWidth>
            <TextInput fullWidth label={translate('resources.infobot/user.fields.email')} source="email"/>
          </Box>

          <Box fullWidth>
            <TextInput fullWidth label={translate('resources.infobot/user.fields.first_name')} source="first_name"/>
          </Box>

          <Box fullWidth>
            <TextInput fullWidth label={translate('resources.infobot/user.fields.last_name')} source="last_name"/>
          </Box>

          <Box fullWidth>
            <TextInput source="patronymic" fullWidth label={translate('resources.infobot/user.fields.patronymic')} />

            <ReferenceInput fullWidth label={translate('resources.infobot/user.fields.position')} source="position" reference="infobot/position">
                <SelectInput optionText="name" />
            </ReferenceInput>
          </Box>

          <ReferenceArrayInput perPage={10e3} label={translate('resources.infobot/user.fields.groups')} source="groups" reference="auth/group">
            <SelectArrayInput fullWidth optionText="name"/>
          </ReferenceArrayInput>

          <BooleanInput onChange={setIsOperator} label={translate('resources.infobot/user.fields.is_operator')} source={'is_operator'}/>
          {isOperator && <TextInput fullWidth label={translate('resources.infobot/user.fields.chat_operator_screen_name')} source="chat_operator_screen_name"/>}

      </Box>
    </SimpleForm>
  )
}

export const UserCreate = props => {
  return (
    <Create undoable={false} {...props} >
      <InjectFields/>
    </Create>
  )
}
