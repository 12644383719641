import React from 'react'
import {
  List, Datagrid, TextField,
} from 'react-admin'

export const ScreenStats = props => {

  const listProps = {
    basePath: "/charts/screens",
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    // history,
    // location,
    // match,
    options: {},
    permissions: undefined,
    resource: "infobot/screen",
  }

  return (
    <List {...listProps} filter={{is_view_counting: true}} bulkActionButtons={false} actions={null} >
      <Datagrid>
        <TextField source="iname"/>
        <TextField source="view_count"/>
      </Datagrid>
    </List>
  )
}
