import React from 'react'
import {useTranslate, SimpleForm, TextInput, useEditController} from 'react-admin'
import {Toolbar} from './Toolbar'

export const QuestionEdit = props => {
  const resource = 'faq_bot_plugin/question'
  const translate = useTranslate()

  const controllerProps = useEditController({
    ...props,
    resource,
    basePath: `/faq_bot_plugin/answer/${props.answer}/questions`,
    undoable: false,
  })

  if (!controllerProps.record) {
    return null
  }

  const save = controllerProps.save
  controllerProps.save = (data, redirectTo, _a = {}) => save(data, `/faq_bot_plugin/answer/${props.answer}/questions`, _a)

  return (
    <SimpleForm
      undoable={false}
      initialValues={{answer: props.answer}}
      basePath={controllerProps.basePath}
      record={controllerProps.record}
      save={controllerProps.save}
      version={controllerProps.version}
      resource={resource}
      toolbar={<Toolbar/>}
    >
      <TextInput fullWidth label={translate('resources.faq_bot_plugin/answer.fields.text')} source="text" multiline rows="8" variant="outlined"/>
    </SimpleForm>
  )
}
