import React, {useRef, } from 'react'
import {useTranslate, } from 'react-admin'
import {Field, Form} from 'react-final-form'
import {authProvider} from '../../../providers/authProvider'
import {useDataProvider} from 'ra-core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Portal from '@material-ui/core/Portal'

const onSubmit = async (options) => {
  const {values, dataProvider, user} = options
  const {new_password, confirmed_password} = values
  const result = await dataProvider('CUSTOM_PUT', `/react_admin/api/infobot/user/${user}/change_password/`, {
    confirmed_password,
    new_password,
  })

  if (result.status === 400) {
    const {confirmed_password, new_password, non_field_errors} = result.json
    const errors = {confirmed_password, new_password}

    if (non_field_errors) {
      errors['new_password'] = non_field_errors[0]
    }

    return errors
  }
}

const validate = values => {
  const errors = {}

  return {}
  const {new_password, confirmed_password} = values

  if (new_password) {
    if (new_password.length < 8) {
      errors.new_password = 'password is too simple'
    }
  } else {
    errors.new_password = 'this field is required'
  }

  if (confirmed_password) {
    if (new_password !== confirmed_password) {
      errors.confirmed_password = 'password do not match'
    }
  } else {
    errors.confirmed_password = 'this field is required'
  }

  return errors
}

export const ChangePassword = (props) => {
  const {user} = props
  const portalRef = useRef(null)
  const dataProvider = useDataProvider()
  const submit = async (values, form, callback) => await onSubmit({values, form, callback, dataProvider, user})
  const translate = useTranslate()

  return (
    <>
      <div ref={portalRef}/>
      <Portal container={portalRef.current}>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({handleSubmit, form, submitting, pristine, values, valid}) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="new_password"
                render={({input, meta}) => (
                  <TextField
                    {...input}
                    error={!!(meta.touched && meta.error || meta.submitError)}
                    fullWidth
                    margin="dense"
                    label={translate('resources.infobot/user.fields.new_password')}
                    helperText={` ${meta.touched && (meta.error || meta.submitError) ? meta.error || meta.submitError : ''}`}
                    variant="filled"
                    type="password"
                  />
                )}
              />

              <Field
                name="confirmed_password"
                render={({input, meta}) => (
                  <TextField
                    {...input}
                    error={!!(meta.touched && meta.error || meta.submitError)}
                    fullWidth
                    margin="dense"
                    label={translate('resources.infobot/user.fields.confirm_password')}
                    helperText={` ${meta.touched && (meta.error || meta.submitError) ? meta.error || meta.submitError : ''}`}
                    variant="filled"
                    type="password"
                  />
                )}
              />

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
              >
               {translate('resources.infobot/user.change_password')}
              </Button>
            </form>
          )}
        />
      </Portal>
    </>
  )
}
