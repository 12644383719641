import React, {useState} from 'react'
import {useEditController, useTranslate, SimpleForm, refreshView} from 'react-admin'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {WidgetEditToolbar} from './WidgetEditToolbar'
import {connect} from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100vw',
    width: 500,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  // form: {
  //   [theme.breakpoints.up('xs')]: {
  //     width: 400,
  //   },
  //   [theme.breakpoints.down('xs')]: {
  //     width: '100vw',
  //     marginTop: -30,
  //   },
  // },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
}))

export const _WidgetEdit = ({widget, onCancel, ...props}) => {
  const [actionGoToScreen, setActionGoToScreen] = useState(false)
  const classes = useStyles()
  const translate = useTranslate()
  const screen = props.id
  const redirectURL = `/infobot/screen/${screen}`
  const controllerProps = useEditController({
    ...props,
    resource: widget.resource,
    id: props.widgetID,
    undoable: false,
  })

  if (!controllerProps.record) {
    return null
  }

  const save = controllerProps.save
  controllerProps.save = (data, redirectTo, _a = {}) => save(data, redirectURL, _a)

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {translate(`resources.${widget.resource}.name`, 1)}
        </Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon/>
        </IconButton>
      </div>
      <SimpleForm
        className={classes.form}
        basePath={controllerProps.basePath}
        record={controllerProps.record}
        save={controllerProps.save}
        version={controllerProps.version}
        resource={widget.resource}
        toolbar={<WidgetEditToolbar/>}
      >
        {widget.editForm(props, {actionGoToScreen, setActionGoToScreen})}
      </SimpleForm>
    </div>
  )
}

const WidgetEdit = connect(undefined, {refreshView})(_WidgetEdit)
export {WidgetEdit}