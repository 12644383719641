import React from 'react'
import {useCreateController, useTranslate, SimpleForm, useRefresh, useRedirect, ReferenceInput, SelectInput} from 'react-admin'
import {Toolbar} from './Toolbar'

export const PlacePathCreate = props => {
  const resource = 'places_bot_plugin/placepath'
  const refresh = useRefresh()
  const translate = useTranslate()
  const redirect = useRedirect()

  const controllerProps = useCreateController({
    ...props,
    resource,
    basePath: ''
  })

  const save = controllerProps.save
  controllerProps.save = (data, redirectTo, _a = {}) => {
    _a.onSuccess = () => {
      refresh()
      redirect(`/places_bot_plugin/place/${props.place}/path`)
    }
    save(data, redirectTo, _a)
  }

  return (
    <SimpleForm
      undoable={false}
      initialValues={{source_place: props.place}}
      basePath={controllerProps.basePath}
      record={controllerProps.record}
      save={controllerProps.save}
      version={controllerProps.version}
      resource={resource}
      toolbar={<Toolbar/>}
    >
      <ReferenceInput perPage={10000} label={translate('resources.places_bot_plugin/place.name', 1)} source="target_place" reference="places_bot_plugin/place">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  )
}
