import React from 'react'
import {
  useTranslate,
  TextInput,
  SimpleForm,
  ImageInput,
  ImageField,
  useEditController, useRefresh, useRedirect
} from 'react-admin'
import {Toolbar} from './Toolbar'

export const PollQuestionEdit = props => {
  const resource = 'poll_bot_plugin/pollquestion'
  const refresh = useRefresh()
  const translate = useTranslate()
  const redirect = useRedirect()

  const controllerProps = useEditController({
    ...props,
    resource,
    basePath: `/poll_bot_plugin/poll/${props.poll}/questions`,
    undoable: false,
  })

  if (!controllerProps.record) {
    return null
  }

  const save = controllerProps.save
  controllerProps.save = (data, redirectTo, _a = {}) => {
    delete data.image
    save(data, () => {
      refresh()
      redirect(`/poll_bot_plugin/poll/${props.poll}/questions`)
    }, _a)
  }

  return (
    <SimpleForm
      undoable={false}
      initialValues={{poll_id: props.id}}
      basePath={controllerProps.basePath}
      record={controllerProps.record}
      save={controllerProps.save}
      version={controllerProps.version}
      resource={resource}
      toolbar={<Toolbar/>}
    >
      <TextInput fullWidth multiline rows="8" variant="outlined" source="text_ru"/>
      <TextInput fullWidth multiline rows="8" variant="outlined" source="text_en"/>
      <ImageInput source="image|rawFile" label="" accept="image/*">
        <ImageField source="image" title="title"/>
      </ImageInput>
    </SimpleForm>
  )
}
