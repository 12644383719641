import React, {useState} from 'react'
import {Edit, TextInput, SimpleForm, useTranslate} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  plane: {
    width: '100%',
    maxWidth: 800
  },
}))

export const PlaceTagEdit = props => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Edit undoable={false} {...props} >
      <SimpleForm
        initialValues={{}}
      >
        <Box className={classes.plane}>

          <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
            <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
              <TextInput fullWidth source="name_ru"/>
            </Box>

            <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>

            </Box>
          </Box>

          <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
            <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
              <TextInput fullWidth source="name_en"/>
            </Box>

            <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>

            </Box>
          </Box>

        </Box>
      </SimpleForm>
    </Edit>
  )
}
