import React from 'react'
import {useCreateController, useTranslate, SimpleForm, useRefresh, useRedirect, ReferenceInput, SelectInput} from 'react-admin'
import {Toolbar} from './Toolbar'
import Box from '@material-ui/core/Box'

export const RoutePlaceCreate = props => {
  const resource = 'places_bot_plugin/routeplace'
  const refresh = useRefresh()
  const translate = useTranslate()
  const redirect = useRedirect()

  const controllerProps = useCreateController({
    ...props,
    resource,
    basePath: ''
  })

  const save = controllerProps.save
  controllerProps.save = (data, redirectTo, _a = {}) => {
    _a.onSuccess = () => {
      refresh()
      redirect(`/places_bot_plugin/route/${props.route}/place`)
    }
    save(data, redirectTo, _a)
  }

  return (
    <SimpleForm
      undoable={false}
      initialValues={{route: props.route}}
      basePath={controllerProps.basePath}
      record={controllerProps.record}
      save={controllerProps.save}
      version={controllerProps.version}
      resource={resource}
      toolbar={<Toolbar/>}
    >
      <Box fullWidth mx={1}>
        <ReferenceInput perPage={10000} fullWidth label={translate('resources.places_bot_plugin/place.name', 1)} source="place" reference="places_bot_plugin/place">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Box>
    </SimpleForm>
  )
}
