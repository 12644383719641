import addUploadFeature from './addUploadFeature'
import drfProvider from './drfProvider'
//import localStorageProvider from './localStorageProvider'

//const localProvider = localStorageProvider()
//const dataProvider = addUploadFeature(drfProvider('/react_admin/api'))

// const dataProviders = [
//   {dataProvider: localProvider, resources: ['profile']},
//   {dataProvider: dataProvider, resources: []},
// ]

//const defaultProvider = drfProvider('/react_admin/api')

const defaultProvider = drfProvider('/react_admin/api', async (url, options) => {

  if(!options.headers.has('Content-Type')) {
    if(options.body instanceof FormData) {
      // don't set Content-Type for multipart/form-data, it will setup with boundary by browser
    } else {
      options.headers.set('Content-Type', 'application/json')
    }
  }

  const response = await fetch(url, options)

  const body = await response.text()
  let json = {}
  try {
    json = JSON.parse(body)
  } catch (e) {
    // todo: empty response(DELETE) or errors?
  }

  return {
    status: response.status,
    headers: options.headers,
    body,
    json,
  }
})

export const rawDataProvider = defaultProvider

export default (type, resource, params) => {
  return defaultProvider(type, resource, params)
  // const dataProviderMapping = dataProviders.find(dp => dp.resources.includes(resource));
  // if(!dataProviderMapping) {
  //   return defaultProvider(type, resource, params);
  // }
  // return dataProviderMapping.dataProvider(type, resource, params);
}