import MenuBookIcon from '@material-ui/icons/MenuBook';
// import {PlaceEdit} from './placeEdit'
import {reviewList} from './reviewList'
// import {PlaceCreate} from './PlaceCreate'

export default {
  name: 'places_bot_plugin/userplaceratereview',
  icon: MenuBookIcon,
  // create: PlaceCreate,
  list: reviewList,
  // edit: PlaceEdit,
};
