import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {GetApp} from '@material-ui/icons'
import {ImagePreview} from '../../../components/ImagePreview/ImagePreview'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: `12px -6px -6px -6px`,
  },
  attachment: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: 4,
    maxWidth: 380,
    maxHeight: 380,
    height: 100,
    margin: 6,
    '&:hover': {
      opacity: .8,
      border: '1px solid rgba(224, 224, 244, .9)',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw - 168px)',
      maxHeight: 'calc(100vw - 168px)',
    },
  },
  image: p => ({
    background: `url(${p.url}) no-repeat center center`,
    backgroundSize: 'contain',
  }),
  logo: {
    margin: 12,
    padding: 6,
    width: 24,
    height: 24,
    borderRadius: 30,
    border: '2px solid #2196f3',
    color: '#2196f3',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    height: 62,
    width: '100%',
  },
  fileText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginRight: 12,
  }
}))

const ImageAttachment = props => {
  const {record, stickyEffect} = props
  const [show, setShow] = useState(false)
  const classes = useStyles({url: record.attachment_file})
  const [image, setImage] = useState(null)

  const setupImage = (src) => {
    if (!src) {
      setImage({
        width: undefined,
        height: undefined,
      })
      return
    }
    const img = new Image()
    img.addEventListener('load', function (e) {
      setImage({
        width: this.naturalWidth,
        height: this.naturalHeight,
      })
      stickyEffect()
    })
    img.src = src
  }

  useEffect(() => setupImage(record.attachment_file), [record])

  return (
    <>
      <ImagePreview src={record.attachment_file} show={show} onClose={() => setShow(false)}/>
      <div
        className={`${classes.image} ${classes.attachment}`}
        onClick={() => setShow(true)}
        style={{width: image ? image.width : 0, height: image ? image.height : 0}}
      />
    </>
  )
}

function downloadURI(uri, name) {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  link.click();
}

const FileAttachment = props => {
  const {record} = props
  const classes = useStyles({url: record.attachment_file})
  const title = record.attachment_file.match(/[^\/]+$/)[0]

  return (
    <>
      <div className={`${classes.file} ${classes.attachment}`} onClick={() => downloadURI(record.attachment_file, title)}>
        <div className={classes.logo}>
          <GetApp/>
        </div>
        <div
          className={classes.fileText}
          title={title}
        >
          {title}
        </div>
      </div>
    </>
  )
}

export const ChatMessageAttachments = props => {
  const {record, attachments, stickyEffect} = props
  const classes = useStyles()

  const data = []
  attachments.list.ids.forEach(id => {
    if(attachments.data[id].chat_message === record.id) {
      data.push(attachments.data[id])
    }
  })

  return (
    <div className={classes.root}>
      {data && data.map((attachment, index) => {
        switch (attachment.attachment_type) {
          case 'photo':
            return <ImageAttachment key={index} record={attachment} stickyEffect={stickyEffect} />
          case 'file':
          default:
            return <FileAttachment key={index} record={attachment}/>
        }
      })}
    </div>
  )
}