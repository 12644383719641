import {Place} from '@material-ui/icons'
import {PlaceEdit} from './placeEdit'
import {placeList} from './placeList'
import {PlaceCreate} from './PlaceCreate'

export default {
  name: 'places_bot_plugin/place',
  icon: Place,
  create: PlaceCreate,
  list: placeList,
  edit: PlaceEdit,
};
