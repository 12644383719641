import React from 'react'
import {Edit, TextInput, SimpleForm} from 'react-admin'
import {parse} from 'query-string'

export const HtmlWidgetEdit = props => {
  let {screen} = parse(props.location.search)
  screen = screen ? parseInt(screen, 10) : null
  const redirect = screen ? `/infobot/screen/${screen}` : false

  return (
    <>
      <Edit undoable={false} {...props} >
        <SimpleForm
          initialValues={{html: '_', screen}}
          redirect={redirect}
        >
          <TextInput source="iname"/>
          <TextInput fullWidth multiline rows="8" variant="outlined" source="html_ru"/>
          <TextInput fullWidth multiline rows="8" variant="outlined" source="html_en"/>
        </SimpleForm>
      </Edit>
    </>
  )
}