import React, {useEffect, useState} from 'react'
import {useQuery, useTranslate} from 'react-admin'
import {makeStyles} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import {numberToColor} from '../../../utils/numberToColor'
import {Line} from 'react-chartjs-2'

const useStyles = makeStyles(theme => ({
  chart: {
    marginTop: 16,
    position: 'relative',
    marginBottom: 24,
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 64px)',
      left: 24,
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 24px)',
      left: 8,
    },
  },
  loader: {
    margin: 36,
  }
}))

const RenderRoutes = props => {
  const classes = useStyles()
  const [data, setData] = useState(null)
  const {info} = props

  let ids = new Set()
  info && info.forEach(record => {
    ids.add(record.route)
  })

  const routes = useQuery({
    type: 'getMany',
    resource: 'places_bot_plugin/route',
    payload: {ids: [...ids]},
  })

  useEffect(() => {
    if (routes.loading || !routes.data) {
      return
    }
    const days = {}
    info.forEach(info => days[info.day] = {})
    info.forEach(info => days[info.day][info.route] = info.uniq_route_count)

    const labels = Object.keys(days).sort((a, b) => new Date(a) - new Date(b))
    const datasets = routes.data.map(route => {
      return {
        label: route.name,
        data: labels.map(data => days[data][route.id] ? days[data][route.id] : 0),
        fill: false,
        backgroundColor: numberToColor(route.id, .4),
        borderColor: numberToColor(route.id, 1),
      }
    })

    setData({
      labels,
      datasets,
    })
  }, [routes.data])

  if (!data || routes.loading) {
    return <CircularProgress className={classes.loader}/>
  }

  return (
    <div className={classes.chart}>
      <Line data={data}/>
    </div>
  )
}

export const RoutesVisits = () => {
  const classes = useStyles()

  const {data, loading, error} = useQuery({
    type: 'getList',
    resource: 'places_bot_plugin/placesroutedailystat',
    payload: {
      filter: {},
      pagination: {page: 1, perPage: 10000},
      sort: {field: 'id'}
    },
  })

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress className={classes.loader}/>
      ) : (
        <RenderRoutes info={data}/>
      )}
    </div>
  )
}