import React from 'react'
import {UserMenu, useTranslate, useSetLocale, useRefresh} from 'react-admin'
import {Translate, ExpandMore} from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tooltip from '@material-ui/core/Tooltip'
import i18nProvider, {languages} from '../providers/i18nProvider'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#fff',
  },
  popover: {
    minWidth: '130px',
  }
}))

const MyUserMenuView = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const refresh = useRefresh()
  const setLocale = useSetLocale()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const currentLanguage = languages.find(l => l.locale === i18nProvider.getLocale())

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const _setLocale = async (locale) => {
    localStorage.setItem('locale', locale)
    await setLocale(locale)
    refresh()
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Tooltip title={translate('language.change')}>
        <Button
          className={classes.button}
          startIcon={<Translate/>}
          endIcon={<ExpandMore/>}
          onClick={handleClick}
        >
          {translate(currentLanguage.name)}
        </Button>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <List component="nav" className={classes.popover}>
          {languages.map(language => (
            <ListItem
              key={language.locale}
              button
              disabled={currentLanguage === language}
              selected={currentLanguage === language}
              onClick={() => _setLocale(language.locale)}
            >
              <ListItemText primary={language.native}/>
            </ListItem>
          ))}
        </List>
      </Popover>

      <UserMenu {...props}>
        {/*<MenuItemLink*/}
        {/*  to="/profile"*/}
        {/*  primaryText="Profile"*/}
        {/*  leftIcon={<SettingsIcon/>}*/}
        {/*/>*/}
      </UserMenu>
    </>
  )

}

export default MyUserMenuView
