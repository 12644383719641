import React from 'react'
import {
  ReferenceManyField,
  Datagrid,
  ImageField,
  TextField,
  EditButton,
  DeleteButton,
  ImageInput,
  TextInput,
  ReferenceField,
  SelectInput,
} from 'react-admin'
import {constants} from '../../../constants'
import {Action} from '../../../components/Action/Action'
import ImageFieldPreview from '../../../components/ImageFieldPreview/ImageFieldPreview'
import {Link} from 'react-router-dom'

const GoToScreenTextField = props => (
  <div style={props.visible ? {} : {display: 'none'}}>
    <TextField {...props} source="iname"/>
  </div>
)

const GoToScreenSelectInput = props => {
  const {actionGoToScreen, setActionGoToScreen} = props.actions
  const isGoToScreen = props.input.value === constants.actionGoToScreenId

  if (isGoToScreen !== actionGoToScreen) {
    setActionGoToScreen(isGoToScreen)
  }
  return <SelectInput {...props} optionText="iname"/>
}

const GoToScreen = props => {
  const isGoToScreen = props.record.action === constants.actionGoToScreenId
  return (
    <ReferenceField {...props} source="go_to_screen" reference="infobot/screen">
      <GoToScreenTextField visible={isGoToScreen ? isGoToScreen : undefined}/>
    </ReferenceField>
  )
}

export const widgets = {
  image: {
    type: 'image',
    resource: 'infobot/imagewidget',
    item: props => {
      const {item, classes, translate} = props
      return (
        <div className={classes.line}>
          {/*<Chip size="small" style={{background: '#bdbdbd'}} label={translate(`resources.infobot/${item.type}widget.name`, 1)} />*/}
          <div className={classes.name}>
            <div className={classes.label}>
              (Widget {translate(`resources.infobot/${item.type}widget.name`, 1)})
              {/*{`, `}*/}
              {/*{translate(`resources.infobot/${item.type}widget.fields.src`, 1)}:*/}
            </div>
            <ImageFieldPreview className={classes.imgContainer} record={{src: item.image}} source="src" title="title"/>
          </div>
        </div>
      )
    },
    referenceManyField: props => (
      <ReferenceManyField {...props} perPage={10000}>
        <Datagrid
          classes={{
            table: props.classes.table,
            headerRow: props.classes.headerRow,
            headerCell: props.classes.headerCell,
            rowCell: props.classes.rowCell,
          }}
        >
          <ImageFieldPreview className={props.classes.imgContainer} source="image" title="title"/>

          <EditButton basePath={`${props.screenURL}/image`} cellClassName={props.classes.controlButton}/>
          <DeleteButton undoable={false} redirect={props.screenURL} cellClassName={props.classes.controlButton}/>
        </Datagrid>
      </ReferenceManyField>
    ),
    createForm: props => (
      <>
        <ImageInput {...props} source="image|rawFile" label="Related pictures" accept="image/*">
          <ImageField source="image" title="title"/>
        </ImageInput>
      </>
    ),
    editForm: props => (
      <>
        <ImageInput {...props} source="image|rawFile" label="Related pictures" accept="image/*">
          <ImageField source="image" title="title"/>
        </ImageInput>
      </>
    )
  },
  html: {
    type: 'html',
    resource: 'infobot/htmlwidget',
    item: props => {
      const {item, classes, translate} = props
      return (
        <div className={classes.line}>
          {/*<Chip size="small" style={{background: '#bdbdbd'}} label={translate(`resources.infobot/${item.type}widget.name`, 1)} />*/}
          <div className={classes.name}>
            <div className={classes.label}>
              (Widget {translate(`resources.infobot/${item.type}widget.name`, 1)})
              {`, `}
              {translate(`resources.infobot/${item.type}widget.fields.iname`, 1)}:
            </div>
            <div className={classes.value}>{item.iname}</div>
          </div>

          <div className={classes.text}>
            <div className={classes.label}>{translate(`resources.infobot/${item.type}widget.fields.html_ru`, 1)}:</div>
            <div className={classes.value}>{item.html_ru}</div>
          </div>

          <div className={classes.text}>
            <div className={classes.label}>{translate(`resources.infobot/${item.type}widget.fields.html_en`, 1)}:</div>
            <div className={classes.value}>{item.html_en}</div>
          </div>
        </div>
      )
    },
    referenceManyField: props => (
      <ReferenceManyField {...props} perPage={10000}>
        <Datagrid
          classes={{
            table: props.classes.table,
            headerRow: props.classes.headerRow,
            headerCell: props.classes.headerCell,
            rowCell: props.classes.rowCell,
          }}
        >
          <TextField source="iname" cellClassName={props.classes.name}/>
          <TextField source="html_ru" cellClassName={props.classes.text}/>
          <TextField source="html_en" cellClassName={props.classes.text}/>

          <EditButton basePath={`${props.screenURL}/html`} cellClassName={props.classes.controlButton}/>
          <DeleteButton undoable={false} redirect={props.screenURL} cellClassName={props.classes.controlButton}/>
        </Datagrid>
      </ReferenceManyField>
    ),
    createForm: () => (
      <>
        <TextInput source="iname"/>
        <TextInput fullWidth multiline rows="8" variant="outlined" source="html_ru"/>
        <TextInput fullWidth multiline rows="8" variant="outlined" source="html_en"/>
      </>
    ),
    editForm: () => (
      <>
        <TextInput source="iname"/>
        <TextInput fullWidth multiline rows="8" variant="outlined" source="html_ru"/>
        <TextInput fullWidth multiline rows="8" variant="outlined" source="html_en"/>
      </>
    ),
  },
  button: {
    type: 'button',
    resource: 'infobot/buttonwidget',
    item: props => {
      const {item, classes, translate, screens, actions} = props
      return (
        <div className={classes.line}>
          <div className={classes.name} title={item.iname}>
            <div className={classes.label}>
              (Widget {translate(`resources.infobot/${item.type}widget.name`, 1)})
              {`, `}
              {translate(`resources.infobot/${item.type}widget.fields.iname`, 1)}:
            </div>
            <div className={classes.value}>{item.iname}</div>
          </div>

          <div className={classes.text2}>
            <div className={classes.label}>{translate(`resources.infobot/${item.type}widget.fields.text_ru`, 1)}:</div>
            <div className={classes.value}>{item.text_ru}</div>
          </div>

          <div className={classes.text2}>
            <div className={classes.label}>{translate(`resources.infobot/${item.type}widget.fields.text_en`, 1)}:</div>
            <div className={classes.value}>{item.text_en}</div>
          </div>

          <div className={classes.text2}>
            <div className={classes.label}>{translate(`resources.infobot/${item.type}widget.fields.action`, 1)}:</div>
            <div className={classes.value}>{actions && (actions.find(action => action.id === item.action) ?? {}).iname}</div>
          </div>

          <div className={classes.text2}>
            {item.action === constants.actionGoToScreenId && <>
              <div className={classes.label}>{translate(`resources.infobot/${item.type}widget.fields.go_to_screen`, 1)}:</div>
              <Link to={`/infobot/screen/${item.go_to_screen}`}>
                <div className={classes.value} style={{color: '#3f51b5'}}>
                  {screens && (screens.find(screen => screen.id === item.go_to_screen) ?? {}).iname}
                </div>
              </Link>
            </>}
          </div>

        </div>
      )
    },
    referenceManyField: (props) => (
      <ReferenceManyField {...props} perPage={10000}>
        <Datagrid
          classes={{
            table: props.classes.table,
            headerRow: props.classes.headerRow,
            headerCell: props.classes.headerCell,
            rowCell: props.classes.rowCell,
          }}
        >
          <TextField source="iname" cellClassName={props.classes.name}/>
          <TextField source="text_ru" cellClassName={props.classes.text}/>
          <TextField source="text_en" cellClassName={props.classes.text}/>

          {/*<TextField source="action_args"/>*/}
          {/*<TextField source="action"/>*/}
          {/*<TextField source="go_to_screen"/>*/}

          <ReferenceField link={false} source="action" reference="infobot/action">
            <TextField source="iname"/>
          </ReferenceField>

          <GoToScreen/>

          <EditButton basePath={`${props.screenURL}/button`} cellClassName={props.classes.controlButton}/>
          <DeleteButton undoable={false} redirect={props.screenURL} cellClassName={props.classes.controlButton}/>
        </Datagrid>
      </ReferenceManyField>
    ),
    createForm: (props, actions) => (
      <>
        <TextInput fullWidth source="iname"/>
        <TextInput fullWidth source="text_ru"/>
        <TextInput fullWidth source="text_en"/>
        {/*<TextInput fullWidth source="action_args"/>*/}
        {/*<TextInput fullWidth source="go_to_screen"/>*/}
        {/*<TextInput fullWidth source="action"/>*/}
        <Action fullWidth/>
      </>
    ),
    editForm: (props, actions) => (
      <>
        <TextInput source="iname"/>
        <TextInput fullWidth source="text_ru"/>
        <TextInput fullWidth source="text_en"/>

        {/*<TextInput fullWidth source="action_args"/>*/}
        {/*<TextInput fullWidth source="go_to_screen"/>*/}
        {/*<TextInput fullWidth source="action"/>*/}

        <Action fullWidth/>
      </>
    )
  }
}
