import * as axios from 'axios'

async function login({username, password}) {
    const res = await axios({
        url: '/api/auth/token/login/',
        method: 'POST',
        data: {
            username,
            password,
        },
    })
    const {auth_token} = res.data
    localStorage.setItem('auth_token', auth_token)
    return Promise.resolve()
}

function updateToken(token) {
    localStorage.setItem('auth_token', token)
}

function logout() {
    localStorage.removeItem('auth_token')
    return Promise.resolve()
}

function checkAuth() {
    const token = localStorage.getItem('auth_token')
    return token ? Promise.resolve() : Promise.reject()
}

async function checkError(err) {
    console.log('err', err)
}

async function getPermissions() {
    // ???
}

export const authProvider = {
    login,
    logout,
    checkAuth,
    checkError,
    getPermissions,
    updateToken,
}
