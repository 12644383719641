import React from 'react'
import {ReferenceManyField, DeleteButton, useTranslate} from 'react-admin'
import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import {Drawer, Button} from '@material-ui/core'
import {Route, useLocation, useHistory} from 'react-router-dom'
import {Edit, Close, Add} from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import {DraggableList} from '../../../../../components/DraggableList/DraggableList'
import {PollQuestionCreate} from './PollQuestionCreate'
import {PollQuestionEdit} from './PollQuestionEdit'
import ImageFieldPreview from '../../../../../components/ImageFieldPreview/ImageFieldPreview'
import i18nProvider, {languages} from '../../../../../providers/i18nProvider'

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  cell: {
    padding: 16,
  },
  drawer: {
    maxWidth: '100vw',
    width: 500,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  plane: {
    width: '100%',
    maxWidth: 800
  },
  imgContainer: {
    padding: 16,
    width: 220,
    '& img': {
      height: '100%',
      width: '100%',
      maxWidth: 220,
      objectFit: 'contain',
      objectPosition: 'left',
      display: 'block',
      margin: 0,
    }
  },
  headerCell: {
    display: 'none',
  },
  rowCell: {
    padding: '6px !important'
  },
}))

const EditButton = props => {
  const translate = useTranslate()
  const location = useLocation()
  const history = useHistory()
  return (
    <Button
      startIcon={<Edit/>}
      color="primary"
      onClick={() => history.push(`${location.pathname}?question=${props.id}`)}
    >
      {translate('ra.action.edit')}
    </Button>
  )
}

export const PollQuestionList = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const location = useLocation()
  const history = useHistory()
  const drawerClose = () => history.push(location.pathname)
  const currentLanguage = languages.find(l => l.locale === i18nProvider.getLocale())

  return (
    <Route path="/poll_bot_plugin/poll/:id/questions">
      {({match, location: {search}}) => {
        const urlParams = new URLSearchParams(search)
        if (!match) {
          if (urlParams.has('question')) {
            urlParams.delete('question')
            history.push(`${location.pathname}?${urlParams.toString()}`)
          }
          return null
        }
        let question = urlParams.get('question')
        question = Number.parseInt(question) ? Number.parseInt(question) : question

        return (
          <>
            <Box className={classes.plane}>
              <Box m={1}>
                <Button
                  startIcon={<Add/>}
                  color="primary"
                  onClick={() => history.push(`/poll_bot_plugin/poll/${props.id}/questions?question=create`)}
                >
                  {translate('resources.poll_bot_plugin/pollquestion.add_question', 1)}
                </Button>
              </Box>

              <ReferenceManyField
                {...props}
                perPage={10000}
                className={classes.plane}
                filter={{poll: props.id}}
                reference="poll_bot_plugin/pollquestion"
                target={'1'}
              >
                <DraggableList
                  ids={props.ids}
                  data={props.data}
                  id={props.id}
                  items={props.pollQuestion}
                  setItems={props.setPollQuestion}
                  disableTypography
                  primary={null}
                  secondary={item => (
                    <div className={classes.flex}>
                      <ImageFieldPreview className={classes.imgContainer} record={{src: item.image}} source="src" title="title"/>
                      <div className={classes.cell}>{item[`text_${currentLanguage.locale}`]}</div>
                    </div>
                  )}
                  controls={item =>
                    <>
                      <EditButton id={item.id}/>
                      <DeleteButton
                        style={{flexShrink: 0, margin: `0 16px`}}
                        record={item}
                        resource='poll_bot_plugin/pollquestion'
                        undoable={false}
                        redirect={`/poll_bot_plugin/poll/${props.id}/questions`}
                      />
                    </>
                  }
                />
              </ReferenceManyField>
            </Box>

            <Drawer
              open={!!question}
              anchor="right"
              onClose={drawerClose}
              classes={{paper: classes.drawerPaper}}
            >
              <div className={classes.drawer}>
                {question === 'create' ? (
                  <>
                    <div className={classes.title}>
                      <Typography variant="h6">
                        {translate('resources.poll_bot_plugin/pollquestion.add_question', 1)}
                      </Typography>
                      <IconButton onClick={drawerClose}>
                        <Close/>
                      </IconButton>
                    </div>

                    <PollQuestionCreate {...props} poll={props.id} />
                  </>
                ) : (
                  <>
                    <div className={classes.title}>
                      <Typography variant="h6">
                        {translate('resources.poll_bot_plugin/pollquestion.edit_question', 1)}
                      </Typography>
                      <IconButton onClick={drawerClose}>
                        <Close/>
                      </IconButton>
                    </div>
                    <PollQuestionEdit {...props} poll={props.id} id={question}/>
                  </>
                )}
              </div>
            </Drawer>
          </>
        )
      }}
    </Route>
  )
}
