import {YandexMap} from '../../../components/YandexMap/YandexMap'
import React, {useRef} from 'react'
import {useForm, useFormState} from 'react-final-form'

export const PlaceMap = () => {
  const formState = useFormState()
  const form = useForm()
  const refGeoObject = useRef()

  const mapInit = (map, ymaps) => {
    const {latitude, longitude, name} = formState.values
    let target = [37.626293, 55.74814]
    if (latitude && longitude) {
      target = [latitude, longitude]
    }
    refGeoObject.current = new ymaps.GeoObject({
      geometry: {
        type: 'Point',
        coordinates: target
      },
      properties: {
        hintContent: name
      },
    }, {
      preset: 'islands#blueDotIcon',
      draggable: true
    })

    refGeoObject.current.events.add('dragend', function (e) {
      const [latitude, longitude] = e.get('target').geometry.getCoordinates()
      form.change('longitude', Math.floor(longitude * 1e6) / 1e6)
      form.change('latitude', Math.floor(latitude * 1e6) / 1e6)
    })

    map.geoObjects.add(refGeoObject.current)

    map.events.add('click', function (e) {
      const [latitude, longitude] = e.get('coords')
      form.change('longitude', Math.floor(longitude * 1e6) / 1e6)
      form.change('latitude', Math.floor(latitude * 1e6) / 1e6)
      refGeoObject.current.geometry.setCoordinates([latitude, longitude])
    })

    map.setCenter(target, 12);
  }

  const mapUpdate = (map, ymaps) => {
    const {latitude, longitude} = formState.values
    if(refGeoObject.current) {
      refGeoObject.current.geometry.setCoordinates([latitude, longitude])
    }
    //map.setCenter([latitude, longitude])
  }

  return (
    <YandexMap init={mapInit} update={mapUpdate} zoom={10} />
  )
}