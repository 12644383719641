import React, {useEffect, useRef, useState} from 'react'
import {useDataProvider, useUpdate, useTranslate, useRefresh} from 'react-admin'
import {rawDataProvider} from '../../../providers/dataProvider'
import {ChatMessage} from './ChatMessage'
import {Send} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import {useStore, useSelector} from 'react-redux'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import {UploadPreview} from '../../../components/UploadPreview/UploadPreview'
import Upload from '../../../components/Upload/Upload'

const useStyles = makeStyles(theme => ({
  root: {
    width: 560,
    maxWidth: 560,
    paddingTop: 48,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 48px)',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
    },
  },
  chat: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    flexShrink: 1,
    overflow: 'auto',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  title: {
    flexGrow: 1,
    marginLeft: 20,
  },
  btn: {
    marginLeft: 6,
    flexGrow: 0,
    backgroundColor: '#3f51b5',
  },
  controls: {
    margin: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'start',
  },
  sendButton: {
    margin: `6px 0 0 12px`,
  },
  attachmentButton: {
    margin: `6px 0 0 0`,
  }
}))

const ReleaseButton = props => {
  const classes = useStyles()
  const refresh = useRefresh()
  const translate = useTranslate()
  const {ready, chatUser} = props
  const [update, {loading, error}] = useUpdate(`chat_bot_plugin/chatuser`, chatUser.id, {
    bot_user: chatUser.bot_user,
    current_operator: null,
  }, chatUser, {onSuccess: refresh})

  return (
    <Button
      className={classes.btn}
      variant="contained"
      color="primary"
      disabled={!ready || loading}
      onClick={update}
    >
      {translate('chat.button.release')}
    </Button>
  )
}

const AcquireButton = props => {
  const classes = useStyles()
  const refresh = useRefresh()
  const translate = useTranslate()
  const {ready, chatUser, currentOperator} = props
  const [update, {loading, error}] = useUpdate(`chat_bot_plugin/chatuser`, chatUser.id, {
    bot_user: chatUser.bot_user,
    current_operator: currentOperator ? currentOperator.id : null,
  }, chatUser, {onSuccess: refresh})

  return (
    <Button
      className={classes.btn}
      variant="contained"
      color="primary"
      disabled={!ready || !currentOperator || loading}
      onClick={update}
    >
      {translate('chat.button.acquire')}
    </Button>
  )
}

export const ChatMessageList = props => {
  const currentOperator = props.currentOperator
  const [stickyChat, setStickyChat] = useState(true)
  const [chatFiles, setChatFiles] = useState([])
  const [attachmentsCount, setAttachmentsCount] = useState(0)
  const classes = useStyles()
  const translate = useTranslate()
  const {dispatch, getState} = useStore()
  const attachments = getState().admin.resources['chat_bot_plugin/chatmessageattachment']
  const dataProvider = useDataProvider()
  const chatMessage = useSelector(state => state.admin.resources['chat_bot_plugin/chatmessage'])
  const {list: {ids}, data} = chatMessage

  const chat = useRef(null)
  const [message, setMessage] = React.useState('')

  const isCurrentOperatorLoading = currentOperator === undefined
  const isCurrentOperatorActive = currentOperator && props.chatUser.current_operator === currentOperator.id

  useEffect(() => {
    function handleScroll() {
      setStickyChat(chat.current.scrollTop + chat.current.offsetHeight === chat.current.scrollHeight)
    }
    chat.current.addEventListener('scroll', handleScroll)
    return () => {
      chat.current.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const stickyEffect = () => {
    if(chat.current && stickyChat) {
      chat.current.scrollTop = chat.current.scrollHeight
    }
  }
  useEffect(() => stickyEffect)

  useEffect(() => {
    const {id: chat_user} = props.chatUser
    let timeout = null
    let version = 0
    const request = async () => {
      timeout = setTimeout(request, 1000)
      const res = await rawDataProvider('GET_ONE', 'chat_bot_plugin/chatmessage', {
        id: `?chat_user=${chat_user}&ordering=id&page=1&page_size=10000`,
      })
      dispatch({
        type: 'RA/CRUD_GET_LIST_SUCCESS',
        payload: {
          data: res.data,
          total: res.data.length
        },
        requestPayload: {
          pagination: {page: 1, perPage: 10000},
          sort: {field: 'id', order: 'ASC'},
          filter: {
            chat_user,
          }
        },
        meta: {
          version,
          resource: 'chat_bot_plugin/chatmessage',
          fetchResponse: 'GET_LIST',
          fetchStatus: 'RA/FETCH_END'
        }
      })
      const newAttachmentsCount = res.data.filter(i => i.has_attachments).length
      if (newAttachmentsCount !== attachmentsCount) {
        const attachmentsRes = await rawDataProvider('GET_ONE', 'chat_bot_plugin/chatmessageattachment', {
          id: `?chat_user=${chat_user}&ordering=id&page=1&page_size=10000`,
        })
        dispatch({
          type: 'RA/CRUD_GET_LIST_SUCCESS',
          payload: {
            data: attachmentsRes.data,
            total: attachmentsRes.data.length
          },
          requestPayload: {
            pagination: {page: 1, perPage: 10000},
            sort: {field: 'id', order: 'ASC'},
            filter: {chat_user,}
          },
          meta: {
            version,
            resource: 'chat_bot_plugin/chatmessageattachment',
            fetchResponse: 'GET_LIST',
            fetchStatus: 'RA/FETCH_END'
          }
        })
        setAttachmentsCount(newAttachmentsCount)
      }
      version++
    }
    request()
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const handleSend = async () => {
    const {id: chat_user, bot_user} = props.chatUser

    if (chatFiles) {
      const chatMessageResponse = await dataProvider('CUSTOM_POST', '/react_admin/api/chat_bot_plugin/chatmessage/', {
        is_draft: true,
        author_type: 'operator',
        // todo: operator? #3
        operator: currentOperator.id,
        chat_user,
        bot_user,
        message,
      })
      const requests = chatFiles.map(item => dataProvider('CUSTOM_POST', '/react_admin/api/chat_bot_plugin/chatmessageattachment/', {
        'attachment_file|rawFile': item.file,
        chat_message: chatMessageResponse.json.id,
        attachment_type: item.file.type.includes('image') ? 'photo' : 'file',
        bot_user,
        chat_user,
      }))
      await Promise.all(requests)
      await dataProvider('CUSTOM_PUT', `/react_admin/api/chat_bot_plugin/chatmessage/${chatMessageResponse.json.id}/`, {
        ...chatMessageResponse.json,
        is_draft: false,
        has_attachments: true,
      })
      setChatFiles([])
    } else {
      await dataProvider('CUSTOM_POST', '/react_admin/api/chat_bot_plugin/chatmessage/', {
        is_draft: false,
        author_type: 'operator',
        operator: currentOperator.id,
        chat_user,
        bot_user,
        message,
      })
    }
    setMessage('')
  }
  const btnLoading = !isCurrentOperatorLoading || currentOperator

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        {isCurrentOperatorActive ? (
          <ReleaseButton chatUser={props.chatUser} ready={btnLoading}/>
        ) : (
          <AcquireButton chatUser={props.chatUser} ready={btnLoading} currentOperator={currentOperator}/>
        )}
        <Typography className={classes.title} variant="h6">
          {props.title}
        </Typography>
        <IconButton onClick={props.onClose}>
          <CloseIcon/>
        </IconButton>
      </div>

      <div className={classes.chat} ref={chat}>
        <div>
          {ids.map(id => <ChatMessage key={id}  attachments={attachments} stickyEffect={stickyEffect} record={data[id]}/>)}
        </div>
      </div>
      <div className={undefined}>
        <div>
          <UploadPreview
            items={chatFiles}
            onRemoveClick={index => setChatFiles(chatFiles.filter((_, i) => i !== index))}
          />
        </div>
        <div className={classes.controls}>
          <Upload
            onFileLoad={(items) => setChatFiles([...chatFiles, ...items])}
            accept='*'
          />
          <TextField
            id="standard-multiline-flexible"
            label={translate('chat.button.enterText')}
            multiline
            rowsMax="3"
            fullWidth
            value={message}
            onKeyPress={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                handleSend()
              }
            }}
            onChange={e => setMessage(e.target.value)}
          />
          <IconButton
            color="primary"
            className={classes.sendButton}
            onClick={handleSend}
            disabled={!message && !chatFiles.length}
          >
            <Send/>
          </IconButton>
        </div>
      </div>
    </div>
  )
}

