import React from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom'
import Card from '@material-ui/core/Card'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslate} from 'react-admin'
import {PlacesMap} from './PlacesMap'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 16,
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: `calc(100% - 600px)`,
    },
  },
  tab: {
    width: 'calc(100% - 32px)',
  }
}))

function TabPanel(props) {
  const {children, value, index, ...other} = props
  const classes = useStyles()

  return (
    <div
      className={classes.tab}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
    >
      {value === index && (
        React.cloneElement(props.children)
      )}
    </div>
  )
}

const tabs = {
  'places': 0,
  'routes': 1,
  0: 'places',
  1: 'routes',
}

export const Map = (props) => {
  const classes = useStyles()
  const match = useRouteMatch()
  const history = useHistory()
  const translate = useTranslate()
  const {tab} = match.params

  if (!tab) {
    history.push(`/map/${tabs[0]}`)
    return null
  }

  const handleChange = (event, newValue) => {
    history.push(tabs[newValue])
  }

  return (
    <>
      <Card className={classes.root}>
        <Tabs value={tabs[tab]} onChange={handleChange}>
          <Tab label={translate('map.places.title')} id={0}/>
          {/*<Tab label={translate('map.routes.title')} id={1}/>*/}
        </Tabs>
        <Divider light/>

        <TabPanel value={tabs[tab]} index={0}>
          <PlacesMap
            location={props.location}
            url={'/map/places'}
            editable={true}
            languages={true}
          />
        </TabPanel>

        {/*<TabPanel value={tabs[tab]} index={1}>*/}
        {/*  <div>???</div>*/}
        {/*</TabPanel>*/}
      </Card>
    </>
  )
}


