import React, {useState} from 'react'
import {
  TabbedForm, useDataProvider, useRedirect, useTranslate, Edit,
  TextInput,
  FormTab,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {PollQuestionList} from './tabs/pollQuestion/PollQuestionList'
import {PollAnswerList} from './tabs/pollAnswer/PollAnswerList'

const useStyles = makeStyles({
  plane: {
    width: '100%',
    maxWidth: 800,
  },
})

const updatePlaceImages = async (pollQuestion, dataProvider) => {
  if (pollQuestion && pollQuestion.length) {
    const ids = []
    const data = {
      needDataMap: true,
    }
    pollQuestion.forEach((question, index) => {
      ids.push(question.id)
      data[question.id] = {...question, order: index}
      delete data[question.id].image
    })
    await dataProvider.updateMany('poll_bot_plugin/pollquestion', {ids, data})
  }
}


export const PollEdit = props => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const redirect = useRedirect()
  const [pollQuestion, setPollQuestion] = useState([])
  const translate = useTranslate()

  return (
    <Edit undoable={false} {...props} >
      <TabbedForm redirect={async (url) => {
        await updatePlaceImages(pollQuestion, dataProvider)
        redirect(url)
      }}>
        <FormTab label={translate('resources.poll_bot_plugin/poll.tabs.main')}>
          <Box className={classes.plane}>


            <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
              <TextInput fullWidth source="iname" label={translate('resources.poll_bot_plugin/poll.fields.iname')}/>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="name_ru" label={translate('resources.poll_bot_plugin/poll.fields.name_ru')}/>
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth source="name_en" label={translate('resources.poll_bot_plugin/poll.fields.name_en')}/>
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_ru"
                           label={translate('resources.poll_bot_plugin/poll.fields.description_ru')}
                />
              </Box>

              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}>
                <TextInput fullWidth multiline rows="6" variant="outlined" source="description_en"
                           label={translate('resources.poll_bot_plugin/poll.fields.description_en')}
                />
              </Box>
            </Box>

            <Box fullWidth display={{xs: 'block', sm: 'flex'}}>
              <Box className={classes.plane} flex={1} mx={{xs: 0, sm: 2}}>
                <ReferenceInput
                  perPage={10000}
                  source="end_screen"
                  reference="infobot/screen"
                  label={translate('resources.poll_bot_plugin/poll.fields.end_screen')}
                >
                  <SelectInput fullWidth optionText="iname"/>
                </ReferenceInput>
              </Box>
              <Box className={classes.plane} flex={1} ml={{xs: 0, sm: 2}}/>
            </Box>
          </Box>
        </FormTab>

        <FormTab label={translate('resources.poll_bot_plugin/poll.tabs.questions')} path={'questions'}>
          <PollQuestionList {...props} pollQuestion={pollQuestion} setPollQuestion={setPollQuestion}/>
        </FormTab>

        <FormTab label={translate('resources.poll_bot_plugin/poll.tabs.answers')} path={'answers'}>
          <PollAnswerList {...props}/>
        </FormTab>

      </TabbedForm>
    </Edit>
  )
}
