import React from 'react'
import {
  useTranslate,
  SimpleForm,
  ImageInput,
  ImageField,
  useEditController, useRefresh, useRedirect
} from 'react-admin'
import {Toolbar} from './Toolbar'

export const RouteImageEdit = props => {
  const resource = 'places_bot_plugin/routeimage'
  const refresh = useRefresh()
  const translate = useTranslate()
  const redirect = useRedirect()

  const controllerProps = useEditController({
    ...props,
    resource,
    basePath: `/places_bot_plugin/route/${props.route}/image`,
    undoable: false,
  })

  if (!controllerProps.record) {
    return null
  }

  const save = controllerProps.save
  controllerProps.save = (data, redirectTo, _a = {}) => save(data, () => {
    refresh()
    redirect(`/places_bot_plugin/route/${props.route}/image`)
  }, _a)

  return (
    <SimpleForm
      undoable={false}
      initialValues={{route: props.route}}
      basePath={controllerProps.basePath}
      record={controllerProps.record}
      save={controllerProps.save}
      version={controllerProps.version}
      resource={resource}
      toolbar={<Toolbar/>}
    >
      <ImageInput source="image|rawFile" label="" accept="image/*">
        <ImageField source="image" title="title"/>
      </ImageInput>
    </SimpleForm>
  )
}
