import React from 'react'
import {
  List, Datagrid, TextField, Filter, TextInput,
  useTranslate, NullableBooleanInput
} from 'react-admin'
import i18nProvider, {languages} from '../../../providers/i18nProvider'



const PostFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput label={translate("ra.action.search")} source="text" alwaysOn />
      <NullableBooleanInput source="is_enabled" alwaysOn />
    </Filter>
  )
};

const LanguageField = ({record = {}}) => {
  const translate = useTranslate()
  const field = languages.find(l => l.locale === record.language)
  return (
    <span>{translate(field.name)}</span>
  )
}

export const AnswerList = props => (
  <List perPage={25} filters={<PostFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="text"/>
      <LanguageField source="language" />
    </Datagrid>
  </List>
)
