import React, {useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'

const ymapsSate = {
  isFetched: false,
  isReady: false,
}

let lastMapIndex = 0

function getMapUID() {
  return `yamap-${lastMapIndex++}`
}

function loadYmaps(setLoaded) {
  if (ymapsSate.isReady) {
    return setLoaded(true)
  }
  if (ymapsSate.isFetched) {
    return ymapsSate.isFetched.then(() => setLoaded(true))
  }
  const script = document.createElement('script')
  script.src = 'https://api-maps.yandex.ru/2.1/?apikey=603fcb75-1823-47bd-949f-6d432091d2a3&lang=ru_RU'
  script.defer = true
  script.async = true
  ymapsSate.isFetched = new Promise(res => {
    script.onload = function () {
      const interval = setInterval(() => {
        if (window.ymaps && window.ymaps.Map) {
          setLoaded(true)
          ymapsSate.isReady = true
          res()
          clearInterval(interval)
        }
      }, 16)
    }
  })
  document.head.appendChild(script)
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 420,
    height: '50%',
    width: '100%',
  },
  loading: {
    backgroundColor: '#ccc',
  }
}))

export const YandexMap = (props) => {
  const {className} = props
  const [loaded, setLoaded] = useState(false)
  const classes = useStyles()
  const mapElRef = useRef(null)
  const mapRef = useRef(null)

  if (!loaded) {
    loadYmaps(setLoaded)
  }

  useEffect(() => {
    if (loaded && mapElRef.current && !mapElRef.current.id) {
      mapElRef.current.id = getMapUID()
      mapRef.current = new window.ymaps.Map(mapElRef.current.id, {
        center: props.center ?? [0,0],
        zoom: props.zoom ?? 7,
        controls: props.controls ?? ['zoomControl'],
      })
      if (props.init) {
        props.init(mapRef.current, window.ymaps)
      }
    }
    return () => {
      if(mapRef.current) {
        mapRef.current.destroy()
        mapRef.current = null
      }
    }
  }, [loaded])

  if (mapRef.current) {
    if (props.update) {
      props.update(mapRef.current, window.ymaps)
    }
  }

  return (
    <div ref={mapElRef} className={`${className ?? null} ${classes.root} ${!loaded ? classes.loading : null}`}/>
  )
}

